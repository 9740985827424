/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup} from "react-bootstrap";
import Config from "../../config";
import {Editor} from '@tinymce/tinymce-react';
import EventTracking from "../common/tracking/event";
import {bindActionCreators} from "redux";
import notify from 'devextreme/ui/notify';
import {CheckBox} from "devextreme-react";
import * as W17F0051Action from "../../actions/w17/w17F0051/w17F0051-actions";
import ButtonSave from "../common/button/button-save";
import ButtonBack from "../common/button/button-back";
import {Loading} from "../common/loading/loading";
import TemplateMail from "../common/drag-and-drop/template-mail";
import {browserHistory} from "react-router";
import TextField from '@material-ui/core/TextField';


class W17F0051Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.timer = null;
        this.state = {
            iPermission: 0,
            loading: false,
            tasks: [],
            disabled: false,
            columns: null,
            columnOrder: ['column-1'],
            code: '',
            id: '',
            subject: '',
            name: '',
            content: '',
            saveSuccess: false
        };
        this.filter = {
            skip: 0,
            limit: 15,

        };
        this.refs = null;
        this.editor = null;
    };


    componentDidMount() {
        if (!this.getInfo()) return;
        const {mode, id} = this.getInfo();
        if (mode !== 'add') {
            this.loadData(id)
        }
    }

    loadData(id) {
        const param = {
            code: id
        };

        this.props.W17F0051Action.loadData(param, (error, data) => {
            if (error) {
                Config.popup.show('INFO', Config.lang("CRM_Loi_chua_xac_dinh"));
                return false;
            }
            this.setState({
                code: data.code,
                disabled: data.disabled,
                id: data.id,
                name: data.name,
                subject: data.subject,
                content: data.template,
            })
        })
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    saveData() {
        const {mode} = this.getInfo();
        const {code, subject, content, name, disabled} = this.state;
        const field = content ? content.match(/\$\{sys\..*?\}/g) : [];
        if (!code) {
            Config.popup.show('INFO', Config.lang('CRM_Ma_khong_duoc_de_trong'));
            document.getElementById('codeMailMaster').focus();
            return false;
        } else if (!name) {
            Config.popup.show('INFO', Config.lang('CRM_Ten_khong_duoc_de_trong'));
            document.getElementById('nameMailMaster').focus();
            return false;
        } else if (!subject) {
            Config.popup.show('INFO', Config.lang('CRM_Tieu_de_khong_duoc_de_trong'));
            document.getElementById('subjectMailMaster').focus();
            return false;
        } else if (!content) {
            Config.popup.show('INFO', Config.lang('CRM_Noi_dung_khong_duoc_de_trong'), null, null, () => {
                this.timer = setTimeout(() => {
                    this.editor.focus();
                }, 300)

            });
            return false;

        }
        const param = {
            code: code,
            subject: subject,
            template: content,
            params: field || [],
            name: name,
            disabled: disabled,
        };
        this.setState({loading: true});
        this.props.W17F0051Action.saveData(param, mode, (error) => {
            if (error) {
                if (error.code === 'TL006') {
                    document.getElementById('codeMailMaster').focus();
                    Config.popup.show('INFO', Config.lang('CRM_Ma_nay_da_ton_tai'));
                } else {
                    Config.popup.show('INFO', Config.lang('CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu'));
                }
            } else {
                this.setState({
                    saveSuccess: true
                });
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);

            }
            this.setState({loading: false})
        })
    }

    goBack() {
        browserHistory.goBack();
    }

    editorChange(e) {
        this.setState({content: e})
    }

    getInfo = (flag) => {
        const {location} = this.props;
        if (location && location.state) {
            return {id: location.state.id, mode: location.state.mode}
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W17F0050');
                return null;
            }
            return false;
        }
    };

    render() {
        const {loading, content, code, subject, name, saveSuccess} = this.state;
        if (!this.getInfo(true)) return null;
        let {mode} = this.getInfo();

        return (
            <div className="page-container">
                {loading && <Loading/>}
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1000"} label={Config.profile.UserID}/>}

                <div className="grid-title">Template Mail</div>

                <Form horizontal={true} id={'frmW17F0051'}>
                    <FormGroup>
                        <Col lg={8} xs={12} sm={12} md={12}>
                            <div id={'MailMasterComponent'}>
                                <FormGroup>
                                    <Col lg={12}>
                                        <ButtonBack name={Config.lang("CRM_Quay_lai")} onClick={() => this.goBack()}
                                                    className={'mgr5'}/>
                                        {mode !== 'view' &&
                                        <ButtonSave disabled={saveSuccess} onClick={() => {
                                            this.saveData()
                                        }} name={Config.lang("CRM_Luu")}/>
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        <TextField
                                            id="codeMailMaster"
                                            InputLabelProps={{style: {fontSize: 16}}}
                                            inputProps={{style: {fontSize: 16}}}
                                            label={<div>{Config.lang("CRM_Ma")}&nbsp;<span
                                                className={'text-red'}>*</span></div>}
                                            margin="none"
                                            value={code}
                                            disabled={mode === 'view' || mode === 'edit' || saveSuccess}
                                            onChange={(e) => this.setState({code: e.target.value})}
                                            fullWidth={true}
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        <TextField
                                            id="nameMailMaster"
                                            InputLabelProps={{style: {fontSize: 16}}}
                                            inputProps={{style: {fontSize: 16}}}
                                            label={<div>{Config.lang("CRM_Ten")}&nbsp;<span
                                                className={'text-red'}>*</span></div>}
                                            value={name}
                                            margin="none"
                                            disabled={mode === 'view' || saveSuccess}
                                            onChange={(e) => this.setState({name: e.target.value})}
                                            fullWidth={true}
                                        />

                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col lg={12}>
                                        <TextField
                                            id="subjectMailMaster"
                                            InputLabelProps={{style: {fontSize: 16}}}
                                            inputProps={{style: {fontSize: 16}}}
                                            label={<div>{Config.lang("CRM_Tieu_de")}&nbsp;<span
                                                className={'text-red'}>*</span></div>}
                                            margin="none"
                                            value={subject}
                                            disabled={mode === 'view' || saveSuccess}
                                            onChange={(e) => this.setState({subject: e.target.value})}
                                            fullWidth={true}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col lg={12} style={{marginTop: '20px'}}>
                                        <Editor onEditorChange={(e) => this.editorChange(e)} value={content}
                                                init={{setup: (el) => this.editor = el}} id={'contentMailMaster'}
                                                disabled={mode === 'view' || saveSuccess}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col lg={12}>
                                        <CheckBox
                                            width={200}
                                            disabled={mode === 'view' || saveSuccess}
                                            text={Config.lang("CRM_Khong_su_dung")}
                                            value={this.state.disabled}
                                            onValueChanged={(e) => {
                                                this.setState({disabled: e.value})
                                            }}
                                        /></Col>
                                </FormGroup>
                            </div>

                        </Col>
                        <Col lg={4} xs={12} sm={12} md={12}>
                            <TemplateMail title={Config.lang('CRM_Tron_truong')} onClick={(e) => {
                                if (mode !== 'view' && !saveSuccess)
                                    this.editor.editorCommands.commands.exec.mceinsertcontent('mceInsertContent', false, ` ${e.code}`)
                            }}/>
                        </Col>
                    </FormGroup>
                </Form>
            </div>

        )
    }
}


export default connect(state => ({
            treeListData: state.w17F1000.treeListData,
            orgListData: state.w17F1000.orgListData,
        }

    ), (dispatch) => ({
        W17F0051Action: bindActionCreators(W17F0051Action, dispatch),
    })
)(W17F0051Page);
