/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {
    FormGroup,
    Form,
    Col,
} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import Config from "../../config";
import PopupView from "../popup/popup-view";
import {CheckBox,
    TextBox,
} from "devextreme-react";
import notify from 'devextreme/ui/notify';
import EventTracking from "../common/tracking/event";
import * as w17F1001Actions from "../../actions/w17F1001-actions";
import * as W17F1000Actions from "../../actions/w17F1000-actions";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';

class W17F1011Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.isSaved=false;
        const data=props.data;
        let groupSalesID;
        let groupSalesTypeID = '';
        this.mode= data.mode;
        if(this.mode==='edit'){
            groupSalesID=data.rowData.GroupSalesID;
            groupSalesTypeID=data.rowData.GroupSalesTypeID;
        }else{
            groupSalesID='';
            groupSalesTypeID='';
        }
        this.state={
            disabled:false,
            groupSalesParent:'',
            groupSalesID: groupSalesID,
            groupSalesTypeID: groupSalesTypeID,
        }
    }

    onHide() {
        let groupSalesParent=this.state.groupSalesParent;
        let groupSalesID=this.state.groupSalesID;
        let isSaved=this.isSaved;
        this.props.onHide(groupSalesID,groupSalesParent,isSaved);

    }

    loadDataGroupSalesType() {
        const uLanguage = Config.language || '84';
        let paramGroupSalesType = {
            "language": uLanguage
        };
        this.props.w17F1001Actions.loadGroupSalesType(paramGroupSalesType,(error,data)=>{
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        })
    }

    checkRootIsExist(){
        this.props.w17F1001Actions.isRoot({},(error,res)=>{
            let groupSalesParent='';
            let check=false;
            const props=this.props;
            if(res){
                if(props.data){
                    const data=props.data;
                    if(data.mode==='add'){
                            if(res.length<=0){
                                groupSalesParent='';
                            }
                            else{
                                groupSalesParent=data.rowData.GroupSalesID;

                            }
                        check=false;
                    }
                    else{
                        if(data.rowData){
                            if(data.rowData.IsRoot===1){
                                groupSalesParent=''
                            }
                            else{
                                groupSalesParent=data.rowData.GroupSalesParentID;
                            }
                        }
                        check=data.rowData.Disabled===1;
                    }
                    this.setState({
                        groupSalesParent:groupSalesParent,
                        disabled:check
                    })
                }
            }
        })
    }

    // checkValidChar(string){
    //     /* eslint-disable */
    //     let format = /[!\s@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    //     /* eslint-enable */
    //
    //     if(format.test(string)){
    //         return true
    //     } else {
    //         return false;
    //     }
    // }

    saveData(){
        const {data,isRoot}=this.props;
        let groupSalesID, groupSalesName, notes, disabled,groupSalesParent, groupSalesType, displayOrder,isRootValue;

        groupSalesID=this.state.groupSalesID;
        // groupSalesID=document.getElementById('groupSalesIDW17F1001').value;
        groupSalesName=document.getElementById('groupSalesNameW17F1001').value;
        notes=document.getElementById('notesW17F1001').value;
        disabled=this.state.disabled?1:0;
        if (data.mode === 'add' && isRoot.length <= 0) {
            groupSalesParent = "";
            isRootValue = 1;

        }
        else if (data.mode === 'edit' && data.rowData.IsRoot === 1) {

            isRootValue = 1;
            groupSalesParent = '';

        }
        else {
            groupSalesParent = this.state.groupSalesParent;
            isRootValue = 0;

        }
        groupSalesType = this.state.groupSalesTypeID ;
        displayOrder=document.getElementById('displayOrderW17F1001').value;
        const param={
            GroupSalesID:groupSalesID,
            GroupSalesNameU:groupSalesName,
            Disabled:disabled.toString(),
            NotesU:notes,
            GroupSalesParentID:groupSalesParent,
            GroupSalesTypeID:groupSalesType,
            DisplayOrder:displayOrder,
            IsRoot:isRootValue.toString()
        };
        if(groupSalesID===''){
            document.getElementById('groupSalesIDW17F1001').getElementsByTagName('input')[0].focus();
            Config.popup.show('INFO', Config.lang("CRM_Ma_nhom_kinh_doanh_khong_duoc_de_trong"));
        }
        else if(groupSalesName===''){
            document.getElementById('groupSalesNameW17F1001').focus();
            Config.popup.show('INFO', Config.lang("CRM_Ten_nhom_kinh_doanh_khong_duoc_de_trong"));

        }
        // else if(this.checkValidChar(groupSalesID)){
        //     document.getElementById('groupSalesIDW17F1001').getElementsByTagName('input')[0].focus();
        //     Config.popup.show('INFO', Config.lang("CRM_Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet"));
        // }
        else{
            if(data.mode==='add') {
                this.props.W17F1000Actions.addNewGroupSales(param, (error, res) => {
                    if (error) {
                        let message = error.message ||  Config.lang("CRM_Loi_chua_xac_dinh");
                        if(error.code==='F1000E006'){
                            document.getElementById('groupSalesIDW17F1001').getElementsByTagName('input')[0].focus();
                            Config.popup.show('INFO', Config.lang("CRM_Ma_kinh_doanh_da_ton_tai"));

                            return false;


                        }
                        Config.popup.show('INFO', message);
                        return false;


                    }
                    this.isSaved=true
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);



                })
            }
            else{
                this.props.W17F1000Actions.editGroupSales(param,(error, res) => {
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    this.isSaved=true
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);



                })
            }
        }



    }


    componentDidMount() {
        this.loadDataGroupSalesType();
        this.checkRootIsExist();

    }

    validateDisplayOrder(e){
        // khong cho nhap so am
        if(e.which===189||e.which===109 || e.which===69){
            e.preventDefault()
        }
    }

    onTextValueChanged(e) {
        switch (e.event.target.name) {
            case "GroupSalesID":
               let str = Config.replaceSymbol(e.event.target.value);
                this.setState({groupSalesID: str});
                break;
            default:
                break;
        }
    }

    render() {
        const {loadGroupSalesType,data,isRoot}=this.props;
        if(data.mode==='add'&&!isRoot)return null;
        return (
            <PopupView
                title={Config.lang("CRM_Them_nhom_kinh_doanh")}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W17F1001"} label={Config.profile.UserID} /> }

                <div className="page-container">
                    <Form horizontal={true} id={'frmW17F1001'}>
                        <FormGroup className={'mgt5'}>
                            <Col md={12} sm={12} xs={12} lg={12}>

                                <ButtonSave name={'Lưu'} onClick={()=>{
                                    this.saveData();
                                }}/>
                            </Col>

                        </FormGroup>
                        <FormGroup>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Ma_nhom_kinh_doanh")}</label>&nbsp;<span
                                className={'text-red'}>(*)</span>
                            </Col>
                            <Col md={9} sm={12} xs={12} lg={9}>
                                <TextBox name={"GroupSalesID"}
                                         id='groupSalesIDW17F1001'
                                         value={this.state.groupSalesID}
                                         disabled={data.mode==='edit'&&true}
                                         onChange={(e) => this.onTextValueChanged(e)}
                                         onPaste={(e)=>this.onTextValueChanged(e)}
                                         className="input-devextreme"
                                         maxLength={20}
                                >
                                    <Validator >
                                        <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                    </Validator>
                                </TextBox>
                                {/*<input  onKeyDown={(e)=>{*/}
                                    {/*if(e.which===32){*/}
                                        {/*e.preventDefault();*/}
                                    {/*}*/}
                                {/*}} onChange={(e) => this.onTextValueChanged(e)} name={"GroupSalesIDW17F1001"} defaultValue={data.mode==='edit'&&data?data.rowData.GroupSalesID:''} disabled={data.mode==='edit'&&true} id='groupSalesIDW17F1001'className={'form-control'}/>*/}
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Ten_nhom_kinh_doanh")}</label>&nbsp;<span
                                className={'text-red'}>(*)</span>
                            </Col>
                            <Col md={9} sm={12} xs={12} lg={9}>
                                <input defaultValue={data.mode==='edit'&&data?data.rowData.GroupSalesNameU:''} id={'groupSalesNameW17F1001'} className={'form-control'}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Truc_thuoc_nhom")}</label>&nbsp;<span
                                className={'text-red'}>(*)</span>
                            </Col>
                            <Col md={6} sm={12} xs={12} lg={6}>

                                <DropDownSaleGroup
                                    disabled={data.mode==='add'?isRoot.length<=0:Number(data.rowData.IsRoot)===1}
                                    value={this.state.groupSalesParent}
                                    onGroupValue = {this.state.groupSalesID}
                                    onChange={(value) => {
                                        this.setState({
                                            groupSalesParent:value
                                        })
                                    }}
                                />
                            </Col>

                            <Col md={3} sm={12} xs={12} lg={3}>
                                <CheckBox
                                    width={200}
                                    text={'Là gốc'}
                                    id={Config.lang("CRM_La_goc")}
                                    elementAttr={{
                                        class: "control-label text-bold"
                                    }}
                                    disabled={true}
                                    value={data.mode==='add'?isRoot.length<=0:Number(data.rowData.IsRoot)===1}

                                />

                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Phan_loai_nhom")}</label>&nbsp;<span
                                className={'text-red'}>(*)</span>
                            </Col>
                            <Col md={3} sm={12} xs={12} lg={3}>

                                <select className={'form-control'}
                                        id={'groupSalesTypeW17F1001'}
                                        // disabled={mode === 'view'}
                                        onChange={(e) => {
                                            this.setState({
                                                groupSalesTypeID: e.target.value
                                            })
                                        }}
                                        value={this.state.groupSalesTypeID}
                                >
                                    <option value={''}></option>
                                    {loadGroupSalesType && loadGroupSalesType.data && loadGroupSalesType.data.length > 0 && loadGroupSalesType.data.map((value, index) => {
                                        return (<option key={index}
                                                        value={value.GroupSalesTypeID}>{value.GroupSalesTypeName}</option>)
                                    })}
                                </select>
                            </Col>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Thu_tu_hien_thi")}</label>
                            </Col>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <input type={'number'} min={0} step={1} onKeyDown={(e)=>{
                                    this.validateDisplayOrder(e)

                                }} defaultValue={data.mode==='edit'&&data?data.rowData.DisplayOrder:''} id={'displayOrderW17F1001'} className={'form-control'}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={3} sm={12} xs={12} lg={3}>
                                <label className={'control-label'}>{Config.lang("CRM_Mo_ta")}</label>
                            </Col>
                            <Col md={9} sm={12} xs={12} lg={9}>

                                <textarea defaultValue={data.mode==='edit'&&data?data.rowData.NotesU:''}  id='notesW17F1001'rows={6} className={'form-control'}></textarea>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={12} sm={12} xs={12} lg={12}>

                                <CheckBox
                                    width={200}
                                    text={Config.lang("CRM_Ngung_hoat_dong")}
                                    id={'disabledW17F1001'}
                                    value={this.state.disabled}
                                    onValueChanged={(e)=>{
                                        this.setState({
                                            disabled:e.value
                                        })
                                    }}

                                />
                            </Col>
                        </FormGroup>


                    </Form>
                </div>

            </PopupView>

        )
    }
}

export default connect(state => ({
        loadGroupSalesType: state.w17F1001.loadGroupSalesType,
        isRoot: state.w17F1001.isRoot,
    }),
    (dispatch) => ({
        w17F1001Actions: bindActionCreators(w17F1001Actions, dispatch),
        W17F1000Actions: bindActionCreators(W17F1000Actions, dispatch),

    })
)(W17F1011Page);
