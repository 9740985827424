/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/06/03 14:00
 * @update 2019/06/03 14:00
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w17f0005Actions from "../../actions/w17f0005-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config';
import {
    Form,
    Col,
} from 'react-bootstrap';
import EventTracking from "../common/tracking/event";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../common/grid-container/grid-container";

class W17F0005Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.salesPersonID = '';
        this.mode = '';
        this.rowData = ''

    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadGrid();
    };

    loadGrid() {
        const uName = Config.profile.UserID;
        const uLanguage = Config.language || '84';

        const params = {
            "Language": uLanguage,
            "UserID": uName,
            "skip": this.filter.skip,
            "limit": this.filter.limit
        };
        this.props.w17f0005Actions.loadW17F0005(params, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }

        })
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F0005", (iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    render() {
        const {dataGrid} = this.props;
        return (
            <div className="page-container">
                <EventTracking action={"FORM"} label={"W17F0005"}/>
                <Form horizontal={true}>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <GridContainer
                            dataSource={dataGrid}
                            title={Config.lang("CRM_Danh_sach_lich_su_gui_mail")}
                            totalItems={dataGrid ? dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0 : 0}
                            itemPerPage={this.filter.limit}
                            isPer={this.state.iPermission}
                            onChangePage={(page) => {
                                this.onChangePage(page)
                            }}
                            onChangePerPage={(per) => {
                                this.onChangePerPage(per)
                            }}
                            filterRow={{
                                visible: true,
                                applyFilter: 'auto'
                            }}
                        >
                            <Column
                                sortOrder={'desc'}
                                dataField={'MailDate'}
                                alignment={'center'}
                                width={250}
                                dataType={'date'}
                                format={'dd-MM-yyyy'}
                                caption={Config.lang("CRM_Ngay_gui")}
                            />
                            <Column
                                dataField={'SalesPersonNameU'}
                                caption={Config.lang("CRM_Nguoi_gui")}
                                width={250}
                            />
                            <Column
                                dataField={'MailSender'}
                                caption={Config.lang("CRM_Email_nguoi_gui")}
                                width={300}/>
                            <Column
                                dataField={'MailTO'}
                                caption={Config.lang("CRM_Email_nguoi_nhan")}
                                width={300}/>
                            <Column
                                dataField={'MailSubject'}
                                width={500}
                                caption={Config.lang("CRM_Tieu_de")}/>
                            <Column
                                dataField={'StatusEmailName'}
                                width={200}
                                caption={Config.lang("CRM_Trang_thai")}/>
                        </GridContainer>
                    </Col>
                </Form>

            </div>

        )
    }
}

export default connect(state => ({
        dataGrid: state.w17f0005.dataGrid,
    }),
    (dispatch) => ({
        w17f0005Actions: bindActionCreators(w17f0005Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F0005Page);