/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/W17F0043.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w17F0043Actions from "../../actions/w17f0043-actions";
import * as userActions from "../../actions/user-actions";

import Config from '../../config';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import ButtonSave from "../common/button/button-save";
import notify from "devextreme/ui/notify";

const TaskContainer = styled.div`
            display: flex;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color:white;
            padding: 3px 5px;
            cursor:all-scroll !important;
            background-color: ${props=>(props.isDragging ? 'rgba(211,211,211,0.4)' : 'white')}
            &:hover {
                background-color: rgba(211,211,211,0.4);
              }  
        `;

const Handle = styled.img`
            width: 15px;
            height: 15px;
            border-radius: 50px;
            margin: 2px 8px 0 0;
        `;

class W17F0043Page extends React.PureComponent {
     constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
        };
         this.filter = {
             skip: 0,
             limit: 20,
             search: ''
         };
     };

     componentDidMount = async () => {
         await this.loadPermission();
         if(this.state.iPermission <= 0) return;
         this.loadGrid();
     };

     async loadPermission() {
         await this.props.userActions.getPermission(Config.profile.UserID, "D17F0043",(iPer)=>{
            this.setState({iPermission: iPer});
         });
     }

     loadGrid(){
         const param = {
             "skip" : this.filter.skip,
             "limit" : this.filter.limit,
             "search": this.filter.search,
         };
         this.props.w17F0043Actions.loadList(param, (error, data) => {
             // if(error){
             //     let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
             //     Config.popup.show('INFO',message);
             //     return false;
             // }
         });
     }

     onSave = (dataSave) => {
         this.props.w17F0043Actions.updateW17F0043({data:JSON.stringify(dataSave)}, (error, data) => {
             if(error){
                 let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                 Config.popup.show('INFO',message);
                 return false;
             }
             else if(data){
                 notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
             }
         });
     };

     render(){
         let {listReport} = this.props;
         let dataTemp = {
             tasks:{},
             columns:{
                 'column-1':{
                     id:'column-1',
                     title:Config.lang('CRM_Danh_sach_bao_cao'),
                     taskIds: [],
                     code: 'CL1',
                 },
                 'column-2':{
                     id:'column-2',
                     title:Config.lang('CRM_Bao_cao_hien_thi'),
                     taskIds: [],
                     code: 'CL2',
                 }
             },
             columnOrder: ['column-1','column-2']
         };
         if(listReport){
             listReport.forEach((key, idx)=>{
                 if(!dataTemp.tasks[key.MReportID]){
                    dataTemp.tasks[key.MReportID] = key;
                    dataTemp.columns[key.Disabled ? 'column-1' : 'column-2'].taskIds.push(key.MReportID);
                 }
             })
         }

         const iconMove = require('../../images/icon-move.png');
         return(
            <div className='W17F0043'>
                <div className="grid-title">Thiết lập báo cáo
                    <span>( <img alt="" src={iconMove} />Chọn và sắp xếp vị trí các mẫu báo cáo bằng thao tác Kéo và Thả. )</span>
                </div>
                {!!listReport && listReport.length > 0 && <Drag data={dataTemp} onSave={this.onSave}/>}
            </div>
         )
     }
}

class Drag extends React.Component {

    constructor(props) {
        super(props);

        this.state= this.props.data;

    };

    componentDidMount() {

    };

    onDragEnd = (result) => {
        //To do reorder our column

        const {columns} = this.state;
        const {destination, source, draggableId} = result;

        if (!destination || (destination && destination.droppableId === source.droppableId && destination.index === source.index)) {
            return; //khong doi vi tri
        }

        let start = columns[source.droppableId];
        let finish = columns[destination.droppableId];

        if (start === finish) { // di chuyển trong cột

            let newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            };

            const newState = {
                ...this.state,
                columns: {
                    ...columns,
                    [start.id]: newColumn
                }
            };

            this.setState(newState);

            return;
        }

        //di chuyển qua cột khác

        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index,1);
        const newStart = {
            ...start,
            taskIds: startTaskIds
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        };

        const newState = {
            ...this.state,
            columns:{
                ...columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        };

        this.setState(newState);
    };

    onSave = () => {
        const {tasks, columns, columnOrder} = this.state;
        let dataNew = [];
        columnOrder.forEach((column, idxColumn)=>{
            let columnItem = columns[column];
            columnItem.taskIds.forEach((task, idxTask)=>{
                let item = tasks[task];
                item.Disabled = columnItem.code === 'CL1' ? 1 : 0;
                item.DisplayOrder = idxTask + (columnItem.code === 'CL1' ? columns['column-2'].taskIds.length : 0);
                dataNew.push(item);
            })
        });
        if(this.props.onSave) this.props.onSave(dataNew);
    };

    render(){
        const {tasks, columns, columnOrder} = this.state;

        return (
            <DragDropContext
                onDragEnd={this.onDragEnd}
            >
                <div className='display_row'>
                    {columnOrder.map(columnId => {
                        const column = {...columns}[columnId];
                        if(!column || !column.taskIds) return null;
                        const tasksL = column.taskIds.map(taskId => tasks[taskId]);
                        return <Column key={column.id} column={column} tasks={tasksL}/>
                    })}
                </div>
                <ButtonSave name={'Lưu'} onClick={this.onSave}/>
            </DragDropContext>
        )
    }
}

class Column extends React.Component {

    render(){
        const {column,tasks} = this.props;

        return (
            <div className='list-column col-lg-6 col-md-6 col-sm-6 col-xs-12' ref='list-column' >
                <div className="title-column">{column.title}</div>
                <Droppable droppableId={column.id}>
                    {(provided, snapshot)=>{
                        return(
                            <div className='list-task'
                                ref={provided.innerRef}
                                // isDraggingOver={snapshot.isDraggingOver}
                                {...provided.droppableProps}
                            >
                                {tasks.map((task, index)=>{
                                    return <Task key={task.MReportID} task={task} index={index}/>
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </div>
        );
    }
}

class Task extends React.Component {

    render(){
        const {task,index} = this.props;
        const iconMove = require('../../images/icon-move-1.png')
        return (
            <Draggable draggableId={task.MReportID} index={index}>
                {(provided, snapshot)=>(
                    <TaskContainer {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          isDragging={snapshot.isDragging}
                    >
                        <Handle src={iconMove} />
                        {task.MReportName}
                    </TaskContainer>
                )}
            </Draggable>
        )
    }
}

export default connect(state => ({
        listReport: state.w17f0043.listReport
    }),
    (dispatch) => ({
        w17F0043Actions: bindActionCreators(w17F0043Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F0043Page);