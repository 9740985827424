/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup,} from "react-bootstrap";
import Config from "../../config";
import TreeList, {
    Column,
    HeaderFilter,
    Selection,
} from 'devextreme-react/tree-list';
import EventTracking from "../common/tracking/event";
import ButtonAdd from "../common/button/button-add";
import ButtonEdit from "../common/button/button-edit";
import ButtonDelete from "../common/button/button-delete";
import {bindActionCreators} from "redux";
import * as w17F1000Actions from "../../actions/w17F1000-actions";
import * as userActions from "../../actions/user-actions";
import * as w17Actions from "../../actions/w17-actions"
import UserProfile from "../common/user-view-container/user-profile";
import W17F1001Modal from "../W17/W17F1001";
import notify from 'devextreme/ui/notify';
import {CheckBox} from "devextreme-react";

class W17F1000Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showW17F1001: false,
            treeData: [],
            iPermission: 0,

        };
        this.showAllTree = false;// 1 show all 0 show not disabled
        this.mode = '';
        this.rowIndx = 0;
        this.rowData = ''
        this.groupSaleID = '';// Lay tu Form W17F1001
        this.groupSaleParent = '';// Lay tu Form W17F1001;
        this.isSaved = false; //Lay tu Form W17F1001
        this.vlSearch = '';

    };


    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadTreeList();
    };

    setDefaultRow(e) {
        if (this.showAllTree) {
            const dataTree = this.state.treeData;
            if (dataTree.length > 0) {
                for (let i = 0; i < dataTree.length; i++) {
                    e.component.expandRow(dataTree[i].GroupSalesID)

                }
            }
        }

        if (e.component.getVisibleRows().length > 0) {
            e.component.expandRow(this.groupSaleParent);
            let data = e.component.getVisibleRows();
            let dataF = data.filter((value) => {
                if ((this.mode === 'add' || this.mode === 'edit') && this.isSaved === true) {
                    return value.data.GroupSalesID === this.groupSaleID

                } else if (this.mode === 'delete') {
                    return value.dataIndex === this.rowIndx - 1
                } else {
                    return value.dataIndex === this.rowIndx
                }
            });
            if (dataF && dataF.length > 0) {
                this.rowIndx = dataF[0].rowIndex;
                this.rowData = dataF[0].data;
                e.component.selectRowsByIndexes(this.rowIndx, true);
                if (dataF[0].data.GroupSalesID !== this.vlGroupSalesID) {
                    this.loadOrgList(dataF[0].data.GroupSalesID);
                    this.vlGroupSalesID = dataF[0].data.GroupSalesID;
                }
            } else {
                let data = e.component.getVisibleRows();
                if (data && data.length > 0) {
                    this.rowIndx = 0;
                    this.rowData = data[0].data;
                    e.component.selectRowsByIndexes(this.rowIndx, true);
                    if (data[0].data && data[0].data.GroupSalesID && data[0].data.GroupSalesID !== this.vlGroupSalesID) {
                        this.loadOrgList(data[0].data.GroupSalesID);
                        this.vlGroupSalesID = dataF[0].data.GroupSalesID;
                    }
                }
            }
        }
    }

    selectedRow(e) {
        let component = e.component;
        component.option('onContentReady', null);
        this.vlGroupSalesID = e.data.GroupSalesID;
        this.loadOrgList(this.vlGroupSalesID);
        this.rowData = e.data;
        this.rowIndx = e.dataIndex;
        let prevClickTime = component.lastClickTime;
        e.component.lastClickTime = new Date();
        this.mode = 'edit';
        if (prevClickTime && (component.lastClickTime - prevClickTime < 300)) {
            //Double click code
            this.setState({
                showW17F1001: true,
            })
        }
    }

    checkDelete(groupSalesID) {
        let sqlCheck = `EXEC W17P5555 '${Config.language}','${Config.profile.UserID}',1,'W17F1000','${groupSalesID}','','','','','','','','',''`;
        let paramCheck = {
            sql: sqlCheck
        };
        this.props.w17Actions.checkStore(paramCheck, (error, data) => {
            let message = "";
            if (error) {
                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            } else if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].length > 0) {
                let status = data.data[0][0].Status;
                let msgAsk = data.data[0][0].MsgAsk;
                message = data.data[0][0].Message;
                if (status === 0) {
                    this.deleteGroupSales(groupSalesID)

                } else {
                    if (msgAsk === 0) {
                        Config.popup.show('INFO', message);
                    } else {
                        Config.popup.show("YES_NO", message,
                            () => {
                                this.deleteGroupSales(groupSalesID)
                            }
                        );
                    }
                }
            } else {
                Config.popup.show('INFO', Config.lang("CRM_Loi_chua_xac_dinh"));
            }
        });

    }

    deleteGroupSales(groupSalesID) {
        const param = {
            GroupSalesID: groupSalesID
        };
        this.props.w17F1000Actions.deleteGroupSales(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);


            } else {
                this.mode = 'delete';
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);

                this.loadTreeList()

            }

        })
    }

    loadTreeList() {
        //refresh data chua index va groupsale id cua dong duoc chon
        const uName = Config.profile.UserID || '';

        const paramTreeList = {
            "UserID": uName,
            "Mode": 1,
        };
        this.props.w17F1000Actions.loadTreeListW17F1000(paramTreeList, (error, data) => {
            let dataF = [];
            if (data) {
                if (this.showAllTree === false) {
                    dataF = data.filter((value) => {
                        return value.Disabled === 0
                    })
                } else {
                    dataF = data
                }
                this.setState({
                    treeData: dataF
                })
            }
        })

    }

    loadOrgList(groupSalesID) {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';

        const paramTreeList = {
            "UserID": uName,
            "Language": uLanguage,
            "GroupSalesID": groupSalesID,
            "Mode": 1,
            "StrSearch": this.vlSearch,
        };
        this.props.w17F1000Actions.loadOrgListW17F1000(paramTreeList, (error, data) => {
        })

    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1100",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    setSearch = (vl) => {
        this.vlSearch = vl;
        this.loadOrgList(this.vlGroupSalesID);
    };

    render() {
        const {orgListData} = this.props;
        return (
            <div className="page-container" style={{margin:0}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1000"} label={Config.profile.UserID}/>}
                {
                    this.state.showW17F1001 && <W17F1001Modal
                        data={{mode: this.mode, rowData: this.rowData}}
                        onHide={(groupSalesID, groupSalesParent, isSaved) => {
                            this.groupSaleID = groupSalesID;
                            this.groupSaleParent = groupSalesParent;
                            this.isSaved = isSaved;
                            this.setState({
                                showW17F1001: false
                            });

                            this.loadTreeList();

                        }}
                    />
                }

                <div style={{marginLeft:'10px'}} className="grid-title">{Config.lang("CRM_Co_cau_kinh_doanh")}</div>
                <Form horizontal={true} id={'frmW17F1000'} style={{margin:'0 15px'}}>
                    <FormGroup>
                        <Col xs={12} md={6} lg={6} sm={6}>
                            <FormGroup>
                                <Col xs={12} md={12} lg={12} sm={12}>

                                    <ButtonAdd disabled={this.state.iPermission < 2} className={'mgr5'}
                                               name={Config.lang("CRM_Them")} onClick={() => {
                                        this.setState({
                                            showW17F1001: true,
                                        })
                                        this.mode = 'add';
                                    }}/>
                                    <ButtonEdit disabled={this.state.iPermission < 3} className={'mgr5'}
                                                name={Config.lang("CRM_Sua")} onClick={() => {
                                        this.setState({
                                            showW17F1001: true,
                                        });
                                        this.mode = 'edit';
                                    }}/>
                                    <ButtonDelete disabled={this.state.iPermission < 4} name={Config.lang("CRM_Xoa")}
                                                  onClick={() => {
                                                      this.mode = 'delete';
                                                      if (this.rowData) {
                                                          Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                                                              this.checkDelete(this.rowData.GroupSalesID);

                                                          })
                                                      }
                                                  }}/>
                                    <CheckBox
                                        width={200}
                                        text={Config.lang("CRM_Hien_thi_ngung_hoat_dong")}
                                        id={'disabledShowW17F1000'}
                                        disabled={this.state.iPermission < 1}
                                        elementAttr={{
                                            class: "mgl5"
                                        }}
                                        onValueChanged={(e) => {
                                            this.rowIndx = 0;
                                            this.mode = '';
                                            this.showAllTree = e.value;
                                            this.loadTreeList()
                                        }}

                                    />

                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col xs={12} md={12} lg={12} sm={12}>
                                    {this.state.treeData.length > 0 && <TreeList
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        wordWrapEnabled={true}
                                        keyExpr={'GroupSalesID'}
                                        dataSource={this.state.treeData}
                                        parentIdExpr={'GroupSalesParentID'}
                                        autoExpandAll={true}
                                        showRowLines={false}
                                        showColumnHeaders={false}
                                        id={'tasks'}
                                        // height={'75vh'}
                                        onContentReady={(e) => this.setDefaultRow(e)}
                                        onRowClick={(e) => {
                                            this.selectedRow(e)
                                        }}
                                        width={'100%'}
                                    >
                                        <HeaderFilter visible={true}/>
                                        <Selection mode={'single'}/>

                                        <Column dataField={'GroupSalesNameU'}
                                                caption={'Status'}


                                                width={300}/>


                                    </TreeList>}
                                </Col>
                            </FormGroup>

                        </Col>
                        <Col xs={12} md={6} lg={6} sm={6}>

                            <FormGroup>
                                <Col xs={12} md={12} lg={12} sm={12}>
                                    {this.state.treeData.length > 0 && <UserProfile setSearch={this.setSearch} dataSource={orgListData}
                                    />}
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </Form>
            </div>

        )
    }
}

export default connect(state => ({
            treeListData: state.w17F1000.treeListData,
            orgListData: state.w17F1000.orgListData,
        }

    ), (dispatch) => ({
        w17F1000Actions: bindActionCreators(w17F1000Actions, dispatch),
        w17Actions: bindActionCreators(w17Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F1000Page);