/**
 * @copyright 2019 @ DigiNet
 * @author THANH TRAM
 * @create 2019/03/15 11:30
 * @update 2019/03/15 11:30
 */

import React from 'react';
import {FormGroup, Form, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config/index';
import {
    Col,
    Input,
} from "reactstrap";
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import * as w17f0020Actions from "../../actions/w17f0020-actions";
import notify from 'devextreme/ui/notify';

class W17F0020Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isSubmit: false,
            oldPassword: '',
            newPassword: '',
            reNewPassword: '',
            checkLenght:false,
            checkLowercase:false,
            checkUppercase:false,
            checkNumber:false,
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
    }

    onChangePass() {
        const oldPassword = document.getElementById("oldPassword").value;
        const newPassword = document.getElementById("newPassword").value;
        const reNewPassword = document.getElementById("reNewPassword").value;
        const param = {
            "oldPassword": oldPassword,
            "newPassword": newPassword,
        };
        // if (newPassword.length < 3) {
        //     document.getElementById('newPassword').focus();
        //     Config.popup.show('INFO', 'Độ dài mật khẩu không hợp lệ.');
        //     return false;
        // }
        if(!this.checkPassSave(newPassword)){
            document.getElementById('newPassword').focus();
            Config.popup.show('INFO',`${Config.lang("CRM_Mat_khau_moi_khong_hop_le")}`);
            return false;
        }
        if (reNewPassword !== newPassword) {
            document.getElementById('reNewPassword').focus();
            Config.popup.show('INFO', `${Config.lang("CRM_Mat_khau_xac_nhan_khong_dung")}`);
            return false;
        }
        else {
            this.props.w17f0020Actions.changePassW17F0020(param, (error, data) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "US002":
                            message = Config.lang("CRM_Thay_doi_mat_khau_khong_thanh_cong");
                            Config.popup.show('INFO', message);
                            break;
                        case "US004":
                            message = Config.lang("CRM_Mat_khau_cu_khong_dung");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                }
                if (data && data.code === 200) {
                    // Show message success and close popup
                    this.setState({isSubmit: false});
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 200);
                    // Auto close when nothing to do
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.setState({isSubmit: false});
                        this.onHide();
                        Config.logout();
                    }, this.timeDeplay);
                }

            });
        }
    }
    checkPassSave(string) {
        let format = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/;
        if(format.test(string)){
            return true;
        } else {
            return false;
        }
    }

    checkPass(mode, string) {

        let checkLengh =/.{6,}/;
        let checkLowercase =/[a-z]{1,}/;
        let checkUppercase =/[A-Z]{1,}/;
        let checkNumber =/[0-9]{1,}/;

        if(checkLengh.test(string)){
            this.setState({checkLenght:true})
        }
        else{this.setState({checkLenght:false})
        }
        if(checkLowercase.test(string)){
            this.setState({checkLowercase:true})
        }
        else{this.setState({checkLowercase:false})
        }
        if(checkUppercase.test(string)){
            this.setState({checkUppercase:true})
        }
        else{
            this.setState({checkUppercase:false})
        }
        if(checkNumber.test(string)){
            this.setState({checkNumber:true})
        }
        else{
            this.setState({checkNumber:false})
        }

    }

    onCustomOldPasswordLbl(id,mode){
        if(mode==='Click'){
            document.getElementById(id).style.fontSize='16px';
            document.getElementById(id).style.color='#177fccde';
        }
        else{
            document.getElementById(id).style.fontSize='12px';
            document.getElementById(id).style.color='#777777';
        }
    }

    handleSelect(key) {
        this.setState({key: key});
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    render() {
        return (
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W17F0020"} label={Config.profile.UserID} /> }

                <Form horizontal={true} className={'mgt5'} id={'changePass'} autoComplete={'off'}>
                    <FormGroup>
                        <Col xs={6} md={6} lg={6} sm={6}>
                                <FormGroup>
                                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                        <h1 className={'text-muted'} id={'passwordTitle'}>{Config.lang("CRM_Doi_mat_khau")}</h1>
                                    </Col>
                                </FormGroup>
                                <div id={'passwordRequired'}>
                                    <p  className={`recomended-text ${this.state.checkLenght?'underLine':''}`}>{Config.lang("CRM_Co_it_nhat_sau_ky_tu")}</p>
                                    <p className={`recomended-text ${this.state.checkUppercase?'underLine':''}`}>{Config.lang("CRM_Co_it_nhat_mot_chu_cai_in_hoa")}</p>
                                    <p className={`recomended-text ${this.state.checkNumber?'underLine':''}`}>{Config.lang("CRM_Co_it_nhat_mot_so")}</p>
                                    <p className={`recomended-text ${this.state.checkLowercase?'underLine':''}`}>{Config.lang("CRM_Co_it_nhat_mot_chu_cai")}</p>
                                </div>
                        </Col>
                        <Col xs={6} md={6} lg={6} sm={6}>
                            <FormGroup>
                                <Col md={11} sm={12} xl={12} xs={12} lg={12}>
                                        <label id={'oldPasswordLbl'} className={'control-label  labelChange text-muted'}>{Config.lang("CRM_Mat_khau_cu")}</label>
                                </Col>
                                <Col md={11} sm={11} xl={11} xs={11} lg={11}>
                                    <Input id={'oldPassword'} type={'password'} onClick={(e)=>{this.onCustomOldPasswordLbl('oldPasswordLbl','Click')}}
                                    onBlur={()=>{this.onCustomOldPasswordLbl('oldPasswordLbl')}}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                    <label id={'newPasswordLbl'} className={'control-label labelChange text-muted'}>{Config.lang("CRM_Mat_khau_moi")}</label>
                                </Col>
                                <Col md={11} sm={11} xl={11} xs={11} lg={11}>
                                    <Input id={'newPassword'} type={'password'} onChange={(e) => {this.checkPass('newPass', e.target.value)}} onClick={(e)=>{this.onCustomOldPasswordLbl('newPasswordLbl','Click')}}
                                           onBlur={()=>{this.onCustomOldPasswordLbl('newPasswordLbl')}}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                    <label id={'reNewPasswordLbl'} className={'control-label labelChange text-muted'}>{Config.lang("CRM_Xac_nhan_mat_khau_moi")}</label>
                                </Col>
                                <Col md={11} sm={11} xl={11} xs={11} lg={11}>
                                    <Input id={'reNewPassword'} type={'password'} onChange={(e) => {this.checkPass('reNew', e.target.value)}} onClick={(e)=>{this.onCustomOldPasswordLbl('reNewPasswordLbl','Click')}}
                                           onBlur={()=>{this.onCustomOldPasswordLbl('reNewPasswordLbl')}}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={11} sm={11} xl={11} xs={11} lg={11}>
                                    <Button type="button" className={'pull-right buttonChangePass'} onClick={() => {this.onChangePass()}}>{Config.lang("CRM_Luu_mat_khau")}</Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

W17F0020Page.propTypes = {

    onHide: PropTypes.func,
};

export default connect(state => ({
        changePassW17F0020: state.w17f0020.changePassW17F0020,
    }),
    (dispatch) => ({
        w17f0020Actions: bindActionCreators(w17f0020Actions, dispatch)
    })
)(W17F0020Page);