/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup,} from "react-bootstrap";
import Config from "../../config";
import {
    Column,
} from 'devextreme-react/tree-list';
import EventTracking from "../common/tracking/event";
import {bindActionCreators} from "redux";
import * as w17F1050Actions from "../../actions/w17/w17F0050/w17F0050-actions";
import * as userActions from "../../actions/user-actions";
import GridContainer from "../common/grid-container/grid-container";
import GridActionBar from "../common/action-bar/grid-action-bar";
import {browserHistory} from "react-router";
import notify from "devextreme/ui/notify";


class W17F0050Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
        };
        this.filter = {
            skip: 0,
            limit: 15,
            keyword: '',

        };
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F0050",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    };

    loadData() {
        this.setState({
            loading:true
        });
        this.props.w17F1050Actions.loadData(this.filter, () => {
            this.setState({
                loading:false
            })
        })
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadData();
    };

    deleteRow(id) {
        const param = {
            code: id
        };
        Config.popup.show('YES_NO', Config.lang("CRM_Ban_co_muon_xoa_du_lieu_nay"), () => {
            this.props.w17F1050Actions.deleteTemplate(param, (error) => {
                if (error) {
                    Config.popup.show('INFO', Config.lang('CRM_Xoa_khong_thanh_cong'));
                    return false;
                }
                notify(Config.lang("Xoa_thanhh_cong"), "success", 2000);
                this.loadData();

            })

        })
    }

    renderAction(data) {
        if (!data || !data.data) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermission}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.onRedirect(data.data.code, 'edit')
                }}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    this.deleteRow(data.data.code)
                }}


            />
        )
    }

    onAddNew() {

        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F0051',
            state: {mode: 'add'}
        })
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.keyword = text;
        this.loadData();
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadData();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadData();
    }

    onRedirect(id, mode = 'edit') {
        if (!id) return null;

        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F0051',
            state: {
                mode: mode,
                id: id
            }
        })
    }

    render() {
        const {dataGrid} = this.props;
        const {iPermission} = this.state;
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F0050"} label={Config.profile.UserID}/>}

                <Form horizontal={true} id={'frmW17F1000'}>
                    <FormGroup>
                        <Col lg={12} xs={12} sm={12} md={12}>
                            <GridContainer
                                dataSource={dataGrid ? dataGrid.data : []}
                                columnAutoWidth={true}
                                title={`${Config.lang("CRM_Danh_sach")} Template Mail`}
                                onAddNew={() => this.onAddNew()}
                                listPerPage={[15, 20, 30, 50]}
                                totalItems={dataGrid ? dataGrid.total : 0}
                                itemPerPage={this.filter.limit}
                                onSearch={(e) => this.onSearch(e)}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}

                            >
                                <Column
                                    cellRender={(data) => this.renderAction(data)}
                                    dataField={'CompanyID'}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={120}
                                    fixed={true}
                                />
                                <Column
                                    dataField={'code'}
                                    caption={Config.lang("CRM_Ma")}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    cellRender={(e) => {
                                        /* eslint-disable */
                                        if (!e.value) return;
                                        if (iPermission >= 1) {
                                            return (
                                                <a onClick={() => this.onRedirect(e.value, 'view')}>{e.value && e.value}</a>)
                                        } else {
                                            return e.value && e.value
                                        }

                                        /* eslint-enable */

                                    }}
                                    width={250}
                                />
                                <Column
                                    dataField={'name'}
                                    caption={Config.lang("CRM_Ten")}
                                    allowSorting={false}
                                    width={300}
                                /> <Column
                                dataField={'subject'}
                                caption={Config.lang("CRM_Tieu_de")}
                                allowSorting={false}
                                width={300}
                            /> <Column
                                dataField={'template'}
                                caption={Config.lang("CRM_Noi_dung")}
                                allowSorting={false}
                                cellTemplate={(e, data) => {
                                    if (data.value) {
										e.innerHTML = data.value;
									}
                                }}

                            /> <Column
                                dataField={'disabled'}
                                caption={Config.lang("CRM_Trang_thai")}
                                width={100}
                                cellRender={(e) => {
                                    return <i className="fas fa-circle" style={{color: e.value ? "#fba219b8": "#1ad61a"}}/>
                                }}
                                allowSorting={true}
                            />
                            </GridContainer>
                        </Col>
                    </FormGroup>
                </Form>
            </div>

        )
    }
}

export default connect(state => ({
        dataGrid: state.w17f0050.dataGrid,
    }), (dispatch) => ({
        w17F1050Actions: bindActionCreators(w17F1050Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F0050Page);
