/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import notify from 'devextreme/ui/notify';
import * as w17F1002Actions from "../../actions/w17F1002-actions";
import * as w17Actions from "../../actions/w17-actions";
import * as fileActions from "../../actions/file-actions";
import EventTracking from "../common/tracking/event";
import {FormGroup, Row, Col, InputGroup, Button as ButtonRB} from 'react-bootstrap';
import InputEmail from "../common/input/input-email";
import PropTypes from 'prop-types';
import Config from '../../config/index';
import {
    TextBox,
    Button,
    ValidationSummary,

} from 'devextreme-react';
import {
    Validator,
    RequiredRule,

} from 'devextreme-react/validator';

import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import ButtonCustom from "../common/button/button-custom";
import ButtonSendMail from "../common/button/button-sendmail";
import draftToHtml from 'draftjs-to-html';

const cssForm = {margin: '15px 15px 0 15px'};
function getValue (value, defaultValue = "") {
    if (!value) return defaultValue;
    if (Array.isArray(value)) {
        return value.map(v => {
            if (typeof v !== "object") {
                return {
                    label: v,
                    value: v
                }
            }
            return v;
        });
    } else if (typeof value === "object") {
        return [value];
    } else {
        return {
            label: value,
            value: value
        }
    }
}
class W17F1002Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            emailTo: '',
            emailCC: [],
            emailBCC: [],
            showCC: false,
            showBCC: false,
            subject: '',
            editorState: EditorState.createEmpty(),
            dataAttachOld: [],
            dataAttach: [],
            contentState: '',
            content: '',
            companyID: this.props.companyID
        };
        this.timer = null;
    };

    _setParamFormMail = (data) => {
        let result = null;
        if (!Array.isArray(data) && typeof data === "object") {
            result = {
                emailTo:     [],
                emailCC:     [],
                emailBCC:    [],
                subject:     "",
                dataAttachOld: [],
                content:     ""
            };
            if (data?.To) {
                const emailTo  = data?.To.replace(/,/, ";").split(";");
                result.emailTo = getValue(emailTo.filter(e => !!e), []);
            }
            if (data?.Cc) {
                const emailCC  = data?.Cc.replace(/,/, ";").split(";");
                result.emailCC = getValue(emailCC.filter(e => !!e), []);
            }
            if (data?.Bcc) {
                const emailBCC  = data?.Bcc.replace(/,/, ";").split(";");
                result.emailBCC = getValue(emailBCC.filter(e => !!e), []);
            }
            if (data?.Subject) result.subject = data?.Subject;
            if (data?.Content) {
                result.content = data?.Content;
                result.editorState = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data?.Content)
                    ));
            }
            if (data?.attachments && data?.attachments.length > 0) {
                result.dataAttachOld = data?.attachments;
            }
        }
        this.setState({...result});
    };

    loadDefaultMail = () => {
        const {data} = this.props;
        if (data && data.FormID) {
            const params = {
                Language:   Config.language || "84",
                ...data,
                FormID:     data?.FormID || "",
                KeyID:      data?.KeyID || "",
                DivisionID: data?.DivisionID || "",
            };
            this.props.w17F1002Actions.loadContentMail(params, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data) {
                    this._setParamFormMail(data);
                }
            });
        }
    };

    componentDidMount () {
        this.loadDefaultMail();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.loadDefaultMail();
        }
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    onTextValueChanged(e) {
        this.setState({subject: e.event.target.value});
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState: editorState,
        });
    }

    onHandleChangeTo(value) {
        this.setState({
            emailTo: value
        });
        // console.log("=============onHandleChangeTo============", value);
    }

    onHandleChangeCC(value) {
        this.setState({
            emailCC: value
        });
    }

    onHandleChangeBCC(value) {
        this.setState({
            emailBCC: value
        });
    }

    onEventSave() {
        this.onFormSubmit();
    }

    onFormSubmit(e) {
        const {FormID = ""} = this.props.data || {};
        const contentEditor = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        const emailTo = this.state.emailTo;
        const subject = this.state.subject;
        if (!emailTo.length) {
            Config.popup.show("INFO", `EmailTo ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            return false;
        }
        if (subject === "") {
            Config.popup.show("INFO", `${Config.lang("Tieu_de")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            return false;
        }
        if (this.state.content === "") {
            Config.popup.show("INFO", `${Config.lang("Noi_dung")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            return false;
        }

        const showCC = this.state.showCC;
        const emailCC = this.state.emailCC;
        const showBCC = this.state.showBCC;
        const emailBCC = this.state.emailBCC;
        let arrEmailCC = [];
        let arrEmailBCC = [];
        if (showCC) {
            if (emailCC.length === 0) {
                return;
            }
            for (let i = 0; i < emailCC.length; i++) {
                arrEmailCC.push(emailCC[i].value);
            }
        }
        if (showBCC) {
            if (emailBCC.length === 0) {
                return;
            }
            for (let i = 0; i < emailBCC.length; i++) {
                arrEmailBCC.push(emailBCC[i].value);
            }
        }
        let strEmailTo = '';
        for (let i = 0; i < emailTo.length; i++) {
            if (strEmailTo !== '') strEmailTo += ",";
            strEmailTo += emailTo[i].value;
        }
        let param = {
            'emailTo': strEmailTo,
            'emailCC': JSON.stringify(arrEmailCC),
            'emailBCC': JSON.stringify(arrEmailBCC),
            'subject': subject,
            'content': contentEditor,
            'FormID': FormID,
            'attachmentsURL': JSON.stringify(this.state.dataAttachOld),
            'attachments': this.state.dataAttach
        };

        this.props.w17F1002Actions.sendMail(param, (error, data) => {
            let statusEmail = 'SUCCESS';
            let message = '';
            if (error) {
                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                statusEmail = 'FAIL';
            }


            // console.log("============arrURL=============",Config.setting.UserID);
            let paramSave = {
                'MailTO': strEmailTo,
                'MailCC': arrEmailCC,
                'MailBCC': arrEmailBCC,
                'MailSubject': subject,
                'MailSender': 'ServerMail',
                'MailContent': contentEditor,
                'ErrorDescription': message,
                'StatusEmail': statusEmail,
                'CompanyID': this.state.companyID
            };
             // console.log("============arrURL=============",data);
            let arrURL = '';
            if (data)
                arrURL = data.urls;
            else{
                Config.popup.show('INFO', Config.lang("CRM_Co_loi_trong_qua_trinh_gui_Mail"));
            }

            this.props.w17F1002Actions.saveMail(paramSave, (err, res) => {
                if (err) {
                    let message = err.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                // console.log("============onFormSubmit=============", res);
                if (res) {
                    if ( res.KeyID !== '') {
                        if (arrURL.length > 0 && arrURL.length === this.state.dataAttach.length) {
                            this.saveFileAttachment(res.KeyID, arrURL);
                        }
                         if (arrURL !=='')  {
                             notify(Config.lang("CRM_Gui_thanh_cong"), "success", 1000);

                             if(this.props.onHide){
                                 this.timer=   setTimeout(()=>{
                                     this.props.onHide()
                                 },1500);
                             }

                         }

                    } else {
                        Config.popup.show('INFO', Config.lang("CRM_Co_loi_trong_qua_trinh_luu_File"));
                    }
                }
            });

        });
    }

    saveFileAttachment(keyID, arrURL) {
        const file = this.state.dataAttach;
        for (let i = 0; i < file.length; i++) {
            const tableName = 'D17T5000';
            const fileName = file[i].name;
            const url = arrURL[i].path;
            const fileSize = file[i].size;
            const fileExp = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();

            let param = {
                'KeyID': keyID,
                'AttachmentID': "",
                'DescriptionU': "Tài liệu đính kèm khi gửi mail",
                'NotesU': "Tài liệu đính kèm khi gửi mail",
                'URL': url,
                'FileName': fileName,
                'FileSize': fileSize,
                'FileExp': fileExp,
                'TableName': tableName
            };
            // console.log("============onFormSubmit=============", param);
            this.props.w17Actions.addW17F0001(param, (error, res2) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
            });

        }
    }

    showHideCC() {
        this.setState({showCC: !this.state.showCC});
    }

    showHideBCC() {
        this.setState({showBCC: !this.state.showBCC});
    }

    checkFileType(filename) {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.png', '.xls', '.xlsx', '.doc', '.docx', '.pdf'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    }

    upLoadAttach() {
        const x = document.getElementById("attachments");
        const arrFile = x.files;
        const sizeLimit = 50000000;
        const that = this;
        let dataAttach = [...that.state.dataAttach];
        for (let i = 0; i < arrFile.length; i++) {

            if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
                Config.popup.show("INFO", Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon") +" " + sizeLimit + ' KB');
            } else if (this.checkFileType(arrFile[i].name) === false) {
                Config.popup.show("INFO", Config.lang("CRM_Dinh_dang_file_khong_hop_le") );
            }
            else { //truong hop hop le
                let file = arrFile[i];
                // console.log("===========upLoadAttach=============", file);


                // file.uploading = true
                dataAttach.push(file);
                that.setState({
                    dataAttach: dataAttach,
                })
            }
        }
    }

    deleteAttach(file, isOld) {
        const {dataAttach, dataAttachOld} = this.state;
        if (isOld) {
            this.setState({dataAttachOld: dataAttachOld.filter((value) => value.path !== file.path)});
        } else {
            this.setState({dataAttach: dataAttach.filter((value) => value.name !== file.name)});
        }
    }

    onContentStateChange(contentState) {
        let str = "";
        for (let block of contentState.blocks) {
            str += block?.text || "";
        }
        this.setState({
            contentState: contentState,
            content: str.trim()
        });
    };

    render() {
        return (
            <div style={cssForm}>
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W17F1002"} label={Config.profile.UserID} /> }

                <FormGroup>
                    <Row>
                        <Col sm={12}>

                            <InputGroup className="mb-3">
                                <InputGroup.Button>
                                    <ButtonRB tabIndex="-1" className="btn-email  btn-group-email">To</ButtonRB>
                                </InputGroup.Button>
                                <InputEmail
                                    className={"input-border-none"}
                                    placeholder=""
                                    value={this.state.emailTo}
                                    handleChange={(data) => {
                                        this.onHandleChangeTo(data)
                                    }}>
                                </InputEmail>
                                <InputGroup.Button>
                                    <ButtonRB tabIndex="-1" className="btn-email  btn-group-email" onClick={() => {
                                        this.showHideCC()
                                    }}>Cc</ButtonRB>
                                    <ButtonRB tabIndex="-1" className="btn-email  btn-group-email" onClick={() => {
                                        this.showHideBCC()
                                    }}>Bcc</ButtonRB>
                                </InputGroup.Button>
                            </InputGroup>
                            <TextBox
                                value={this.state.emailTo.length > 0 ? 'n' : ''}
                                className="input-devextreme hide"
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Ban_chua_nhap_Email_nguoi_nhan")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>

                {this.state.showCC &&
                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <InputGroup className="mb-3">
                                <InputGroup.Button>
                                    <ButtonRB tabIndex="-1" className="btn-email  btn-group-email ">Cc</ButtonRB>
                                </InputGroup.Button>
                                <InputEmail
                                    className={"input-border-CC"}
                                    placeholder=""
                                    value={this.state.emailCC}
                                    handleChange={(data) => {
                                        this.onHandleChangeCC(data)
                                    }}>
                                </InputEmail>
                            </InputGroup>
                            <TextBox
                                value={this.state.emailCC.length > 0 ? 'n' : ''}
                                className="input-devextreme hide"
                            >
                                <Validator>
                                    <RequiredRule message={ Config.lang("CRM_Ban_chua_nhap_Email_Cc")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>
                }

                {this.state.showBCC &&
                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <InputGroup className="mb-3">
                                <InputGroup.Button>
                                    <ButtonRB tabIndex="-1" className="btn-email  btn-group-email">Bcc</ButtonRB>
                                </InputGroup.Button>
                                <InputEmail
                                    className={"input-border-CC"}
                                    placeholder=""
                                    value={this.state.emailBCC}
                                    handleChange={(data) => {
                                        this.onHandleChangeBCC(data)
                                    }}>
                                </InputEmail>
                            </InputGroup>
                            <TextBox
                                value={this.state.emailBCC.length > 0 ? 'n' : ''}
                                className="input-devextreme hide"
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Ban_chua_nhap_Email_Bcc")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>
                }


                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <TextBox name={"Subject"}
                                     value={this.state.subject}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme"

                            >
                            </TextBox>
                            <TextBox
                                value={this.state.subject}
                                className="input-devextreme hide"
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Ban_chua_nhap_chu_de_cua_Email")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <Editor
                                style={{height: '500px'}}
                                editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => {
                                    this.onEditorStateChange(e)
                                }}
                                onContentStateChange={(e) => {
                                    this.onContentStateChange(e)
                                }}
                            />
                            <TextBox
                                value={this.state.content}
                                className="input-devextreme hide"
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Ban_chua_nhap_noi_dung_Email")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            {this.state.dataAttachOld.map((value, index) => {
                                return (
                                    <label className={'mgr10 item-attachtment'} key={index}>{value.filename} <i
                                        className="mgl10 fal fa-times text-red " onClick={() => {
                                        this.deleteAttach(value, true)
                                    }}/> </label>);
                            })}
                            {this.state.dataAttach.map((value, index) => {
                                return (
                                    <label className={'mgr10 item-attachtment'} key={index}>{value.name} <i
                                        className="mgl10 fal fa-times text-red " onClick={() => {
                                        this.deleteAttach(value)
                                    }}/> </label>);
                            })}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={7}>
                            <ValidationSummary id={'summary'}></ValidationSummary>
                        </Col>
                        <Col sm={5} className={"jus-end"}>
                            <ButtonCustom icon={'fas fa-paperclip'} isAwesome={true} className={'mgr5'}
                                          color={'text-green'} name={Config.lang("CRM_Dinh_kem")}
                                          onClick={() => {
                                              document.getElementById('attachments').click();
                                          }}
                            />
                            <input id={'attachments'} type="file" name="attachments[]"
                                   className="media_upload_input hide" multiple
                                   onChange={() => {
                                       this.upLoadAttach()
                                   }}/>
                            <ButtonSendMail
                                disabled={this.state.isUploaded === true || this.state.fileName === '' || this.state.isSubmit === true}
                                onClick={() => this.onEventSave()} className={"mgr5"}
                                name={Config.lang("CRM_Gui_mail")}/>
                            {/*<ButtonClose name={Config.lang("CRM_Dong1")} onClick={() => {*/}
                                {/*this.props.onHide()*/}
                            {/*}}></ButtonClose>*/}
                            <Button id="btnSubmit" onClick={(e) => this.onFormSubmit(e)} useSubmitBehavior={true} className="hide"/>
                        </Col>
                    </Row>
                </FormGroup>

            </div>
        )
    }
}

W17F1002Page.propTypes = {
    onHide: PropTypes.func,
    companyID: PropTypes.string,
    /**
     * {
            FormID: "",
            KeyID: ",
            DivisionID: ""
        }
     */
    data: PropTypes.object,
};

export default connect(state => ({
        sendMail: state.w17.sendMail,
        saveMail: state.w17.saveMail,
        addW17F0001: state.w17.addW17F0001,
    }),
    (dispatch) => ({
        w17F1002Actions: bindActionCreators(w17F1002Actions, dispatch),
        w17Actions: bindActionCreators(w17Actions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch)
    })
)(W17F1002Page);
