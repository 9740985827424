/**
 * @copyright 2023 @ DigiNet
 * @author ANHTAI
 * @create 2023/04/15 15:54
 * @update 2023/04/15 15:54
 */

import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Config from '../../../config';
import { Column, Summary, TotalItem, } from 'devextreme-react/data-grid';
import EventTracking from "../../common/tracking/event";
import GridContainer from "../../common/grid-container/grid-container";
import { browserHistory } from "react-router";
import * as w17f1005Actions from "../../../actions/w17/w17F1005/w17F1005-actions";
import DynamicStepComponent from "../../common/dynamic-step-progress-bar/dynamic-step-progress-bar";
import _ from "lodash";
import PropTypes from 'prop-types';
import { Row, Col, Label as LabelCore, Button, Typography as TypographyCore, NumberInput } from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import moment from "moment";
moment.locale('vi');

const useStyles = makeStyles(({ colors = {} }) => ({
    moneyInputColumn: {
        width: '100%',
        margin: 0,
        '& input': {
            textAlign: 'right'
        }
    },
    numberInput: {
        '& input': {
            color: colors?.secondary
        }
    },
}));

const formID = 'W17F1005';
const Label = (props) => <LabelCore type={"h4"} {...props} />;
const Typography = (props) => <TypographyCore type={"p2"} color={"secondary"} {...props} />;
const NumberInputRO = (props) => <NumberInput nonStyle readOnly thousandSeparator={','} {...props} />;

class W17F1005Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            isVisibleColumn: false,
            dataMaster: {},
            dataProgressStatus: {},
            masterCaption: [],
            gridCaption: [],
            dataGrid: [],
        };
    };

    componentDidMount() {
        if (!this.getInfo()) return;
        this.loadMaster(); // Master
        this.loadDetail(); // Grid
        this.loadSettingForm(); // Setting Form
        this.loadListStatus();
        this.loadMasterCaption(); // Dynamic master caption
    };

    getInfo = (flag) => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('quotationID')) {
            return {
                quotationID: url.get('QuotationID') || "",
                screenID: url.get('ScreenID') || ""
            };
        } else if (!Config.isEmpty(location?.state)) {
            const { QuotationID = "" } = location.state?.data || {};
            return {
                quotationID: QuotationID,
                screenID: location.state?.screenID || "",
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W19F1000');
                return null;
            }
            return false;
        }
    };

    loadMaster = () => {
        const { quotationID } = this.getInfo();
        const params = {
            FormID: formID,
            QuotationID: quotationID,
            DataType: "LoadMasterPriceQuote",
            CompanyID: "",
        };
        this.props.w17f1005Actions.loadMaster(params, (error, data) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                if (_.isObject(data)) {
                    if (data?.CurrencyID) this.loadOriginalDecimal(data.CurrencyID);
                    this.setState({ dataMaster: data });
                }
            }
        })
    };

    loadDetail = () => {
        const { quotationID } = this.getInfo();
        const params = {
            FormID: formID,
            QuotationID: quotationID,
            TranTypeID: "",
        };
        this.props.w17f1005Actions.loadDetail(params, (error, data) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                const { Caption: gridCaption = [], Data: dataGrid = [] } = data || {};
                const isVisibleColumn = gridCaption.some(item => item?.Display === 1);
                this.setState({
                    gridCaption,
                    dataGrid,
                    isVisibleColumn
                });
            }
        })
    };

    loadListStatus = () => {
        const { quotationID } = this.getInfo();
        const params = {
            FormID: formID,
            QuotationID: quotationID,
            DataType: "StatusPriceQuote",
            CompanyID: ""
        };
        this.props.w17f1005Actions.loadListStatus(params, (error, data) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                const { Name = [], StatusValue = 0 } = data || {};
                const ListStatus = [];
                for (let i = 0; i < Name.length; i++) {
                    const item = Name[i];
                    if (item?.Display !== 1) continue;
                    ListStatus.push({
                        number: item?.StatusID,
                        title: item?.StatusName,
                        info: item?.Info || [],
                        display: item?.Display
                    });
                };
                this.setState({
                    dataProgressStatus: {
                        Status: {
                            ListStatus,
                            TotalStatusID: Name.length ?? 0
                        },
                        StatusValue
                    }
                });
            }
        })
    };

    loadSettingForm = () => {
        this.props.w17f1005Actions.loadSettingForm((error) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            }
        })
    };

    loadMasterCaption = () => {
        const { quotationID } = this.getInfo();
        const params = {
            FormID: formID,
            QuotationID: quotationID,
            DataType: "MasterPriceQuote",
            CompanyID: ""
        };
        // Do nguon ten va datafield cot dong
        this.props.w17f1005Actions.loadMasterCaption(params, (error, data) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                this.setState({ masterCaption: data });
            }
        })
    };

    loadOriginalDecimal = (CurrencyID = "") => {
        const params = {
            CurrencyID
        };
        this.props.w17f1005Actions.loadOriginalDecimal(params, (error) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            }
        });
    };

    goBack = () => {
        const { screenID } = this.getInfo();
        if (screenID) {
            browserHistory.push(Config.getRootPath() + screenID);
        } else {
            browserHistory.goBack();
        }
    };

    unicodeConvert = (str) => {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    };

    onSearch = (text) => {
        let grid = [...this.state.dataGrid], i = 0;
        for (i; i < grid.length; i++) {
            const stringSearch = grid[i]?.InventoryID + grid[i]?.InventoryName;
            grid[i].hide = this.unicodeConvert(stringSearch).search(new RegExp(this.unicodeConvert(text), "i")) < 0;
        }
        this.setState({
            dataGrid: grid
        });
    };

    getDecimalPlace = (fieldName = "") => {
        let decimalNum = 0;
        const { dataOriginalDecimal: { OriginalDecimal = 0 },
            dataSettingForm: { D07_QuantityDecimals = 0, D90_ConvertedDecimals = 0 } } = this.props;
        const { gridCaption } = this.state;
        switch (fieldName) {
            case "OUnitPrice":
            case "AdjustedUnitPrice":
            case "Amount":
            case "UnitPrice":
            case "TotalAdjustedAmount":
                decimalNum = OriginalDecimal;
                break;
            case "Quantity":
                decimalNum = D07_QuantityDecimals;
                break;
            case "CAmountTmp":
            case "OAmountTmp":
            case "CAmount":
            case "TotalAdjustedCAmount":
                decimalNum = D90_ConvertedDecimals;
                break;
            default:
                if (fieldName.indexOf("ACol") >= 0) {
                    const currentACol = gridCaption && gridCaption.find(item => item?.ColID === fieldName);
                    if (!_.isEmpty(currentACol)) decimalNum = currentACol?.DecimalPlace ?? 0;
                }
                break;
        }
        return decimalNum;
    };

    renderNumberFormat = (e) => {
        if (!e) return false;
        const classes = useStyles();
        const { dataField = "" } = e?.column || {};
        const { data = {} } = e?.row || {};
        let decimalValue = data?.[dataField] || null;
        if (_.isUndefined(decimalValue)) return;
        const decimalNum = this.getDecimalPlace(dataField);
        if (!_.isEmpty(decimalValue) && _.isString(decimalValue)) decimalValue = _.toNumber(decimalValue);
        return <NumberInputRO className={classes.moneyInputColumn} decimalDigit={decimalNum} value={decimalValue} />
    };

    renderDynamicMaster = () => {
        const { dataMaster, masterCaption } = this.state;
        if (_.isEmpty(masterCaption) && _.isEmpty(dataMaster)) return;
        const classes = useStyles();
        const { dataSettingForm: { D90_ConvertedDecimals = 0 } } = this.props;
        const data = [];
        for (let i = 0; i < masterCaption.length; i++) {
            const item = masterCaption[i];
            if (item?.Display === 0) continue;
            let valueType = "";
            const value = dataMaster?.[item?.FieldName] || "";
            switch (item?.DataType) {
                case "D":
                    valueType = <Typography>{Config.isValidDateTime(value) ? moment(value).format('DD/MM/YYYY') : ""}</Typography>
                    break;
                case "N":
                    valueType = <NumberInputRO className={classes.numberInput} decimalDigit={D90_ConvertedDecimals} value={value} />
                    break;
                default:
                    valueType = <Typography>{value}</Typography>
                    break;
            }
            data.push(<Col xs={12} sm={6} md={6} lg={4} key={i}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5}>
                        <Label>{item?.CaptionName || ""}</Label>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7}>
                        {valueType}
                    </Col>
                </Row>
            </Col>)
        }
        return data;
    };

    numberFormatCol = (number = 0, fieldName = "") => {
        const stringNum = number.toString();
        let digitNum = fieldName ? this.getDecimalPlace(fieldName) : 0;
        const isDigit = stringNum.indexOf(".") > -1;
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: isDigit ? digitNum : 0,
            maximumFractionDigits: digitNum
        });
        const _number = formatter.format(String(number)) || 0;
        return _number;
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { gridCaption, isVisibleColumn, dataProgressStatus, dataGrid } = this.state;
        const { Status = [], StatusValue = 0 } = dataProgressStatus;
        return (
            <div id={"clRequiredField"} className={"page-container"}>
                <EventTracking action={"FORM"} label={"W17F1005"} />
                <Row>
                    <Col xs={12}><Typography color={"info"} type={"h4"}>{Config.lang("Chi_tiet_bao_gia")}</Typography></Col>
                    <Col xs={12}>
                        <div className={"wrap align-center"}>
                            <Button
                                size={"small"}
                                color={'warning'}
                                startIcon={"Back"}
                                label={Config.lang('Quay_lai')}
                                labelProps={{ style: { color: 'initial' } }}
                                onClick={this.goBack}
                            />

                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={"page-requiredField pdr4x pdb4x pdl4x"}>
                            <div className={"grid-title title-field mgb4x"}><Label>{Config.lang("Thong_tin_bao_gia")}</Label></div>
                            <Row>
                                {this.renderDynamicMaster()}
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <DynamicStepComponent step={StatusValue} statusList={Status} />
                    </Col>
                    <Col xs={12} className={'grid-full-width'}>
                        <GridContainer
                            widthFull={true}
                            dataSource={dataGrid.filter(item => !item?.hide)}
                            columnResizingMode={'widget'}
                            onSearch={text => this.onSearch(text)}
                            buttonCRM={[
                                {
                                    buttonType: "label",
                                    dataFieldLb: `${this.numberFormatCol(dataGrid?.[0]?.CTotalAmount, "CTotalAmount") ?? 0}`,
                                    buttonName: `${Config.lang("Tong_gia_tri_bao_gia")}: `,
                                    align: 'right',
                                },
                                // {
                                //     buttonType: "label",
                                //     dataFieldLb: `${dataCTotalAmount}`,
                                //     buttonName: `${Config.lang("Tong_gia_tri_bao_gia_QD")}: `,
                                //     className: `${!!isVisibleColumn ? '' : 'hide'} pdl2x`,
                                //     align: 'right',
                                // },
                            ]}
                        >
                            <Column
                                dataField={'Action'}
                                caption={Config.lang("STT")}
                                alignment={'center'}
                                cellRender={(e) => e.rowIndex + 1}
                                width={70}
                            />
                            <Column
                                dataField={'InventoryID'}
                                caption={Config.lang("Ma_hang")}
                                width={120}
                            />
                            <Column
                                dataField={'InventoryName'}
                                caption={Config.lang("Ten_hang")}
                                width={380}
                            />
                            <Column
                                dataField={'UnitID'}
                                alignment={'center'}
                                caption={Config.lang("DVT")}
                                width={90}
                            />
                            <Column
                                dataField={'OUnitPrice'}
                                alignment={"right"}
                                dataType={"number"}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("DG_truoc_thue")}
                                width={160}
                            />
                            <Column
                                dataField={'Quantity'}
                                alignment={"right"}
                                dataType={"number"}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("SL")}
                                width={90}
                            />
                            <Column
                                dataField={'OAmountTmp'}
                                alignment={"right"}
                                dataType={"number"}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("Thanh_tien")}
                                width={160}
                            />
                            <Column
                                dataField={'CAmountTmp'}
                                alignment={"right"}
                                dataType={"number"}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("Thanh_tien_QD")}
                                width={160}
                            />
                            {gridCaption.length > 0 && gridCaption.map((item, index) => {
                                const dataField = item?.ColID || "";
                                const caption = item?.ColName || "";
                                const visible = item?.Display === 1;
                                return (<Column
                                    key={index}
                                    width={180}
                                    alignment={"right"}
                                    dataType={"number"}
                                    dataField={dataField}
                                    visible={visible}
                                    cellRender={this.renderNumberFormat}
                                    caption={caption} />)
                            })}
                            <Column
                                width={180}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'AdjustedUnitPrice'}
                                cellRender={this.renderNumberFormat}
                                visible={isVisibleColumn} //Hiển thị khi ít nhất 1 cột Acol01 hiển thì, ngược lại thì ẩn
                                caption={Config.lang("Don_gia_sau_DC")} />
                            <Column
                                width={180}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'Amount'}
                                cellRender={this.renderNumberFormat}
                                visible={isVisibleColumn} //Hiển thị khi ít nhất 1 cột Acol01 hiển thì, ngược lại thì ẩn
                                caption={Config.lang("Thanh_tien_sau_DC")} />
                            <Column
                                width={180}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'CAmount'}
                                cellRender={this.renderNumberFormat}
                                visible={isVisibleColumn} //Hiển thị khi ít nhất 1 cột Acol01 hiển thì, ngược lại thì ẩn
                                caption={Config.lang("Thanh_tien_sau_DC_QD")} />
                            <Column
                                width={140}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'VATRate'}
                                format={{ type: "percent" }}
                                caption={`${Config.lang("Thue_suat")}(%)`} />
                            <Column
                                width={160}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'UnitPrice'}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("Don_gia_sau_thue")} />
                            <Column
                                width={160}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'TotalAdjustedAmount'}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("Thanh_tien_sau_thue")} />
                            <Column
                                width={180}
                                alignment={"right"}
                                dataType={"number"}
                                dataField={'TotalAdjustedCAmount'}
                                cellRender={this.renderNumberFormat}
                                caption={Config.lang("Thanh_tien_sau_thue_QD")} />
                            <Summary>
                                <TotalItem
                                    column={'Quantity'} // Số lượng
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "Quantity")}
                                />
                                <TotalItem
                                    column={'OAmountTmp'} // Thành tiền
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "OAmountTmp")}
                                />
                                <TotalItem
                                    column={'CAmountTmp'}
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "CAmountTmp")}
                                />
                                {gridCaption.length > 0 && gridCaption.map((item) => {
                                    const colName = item?.ColID || "";
                                    return (
                                        <TotalItem
                                            key={colName}
                                            summaryType={'sum'}
                                            displayFormat={"{0}"}
                                            column={colName}
                                            valueFormat={(e) => this.numberFormatCol(e, colName)}
                                        />
                                    )
                                })}
                                <TotalItem
                                    column={'Amount'} // Thành tiền sau ĐC
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "Amount")}
                                />
                                <TotalItem
                                    column={'CAmount'} // Thành tiền sau ĐC QĐ
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "CAmount")}
                                />
                                <TotalItem
                                    column={'VATRate'} // Thuế suất
                                    summaryType={'sum'}
                                    displayFormat={"{0}%"}
                                />
                                <TotalItem
                                    column={'UnitPrice'} // Đơn giá sau thuế
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "UnitPrice")}
                                />
                                <TotalItem
                                    column={'TotalAdjustedAmount'} // Thành tiền sau thuế
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "TotalAdjustedAmount")}
                                />
                                <TotalItem
                                    column={'TotalAdjustedCAmount'} // Thành tiền sau thuế QĐ
                                    summaryType={'sum'}
                                    displayFormat={"{0}"}
                                    valueFormat={(e) => this.numberFormatCol(e, "TotalAdjustedCAmount")}
                                />
                            </Summary>
                        </GridContainer>
                    </Col>
                </Row>
            </div>
        )
    }
}

W17F1005Page.propTypes = {
    dataSettingForm: PropTypes.object,
    dataOriginalDecimal: PropTypes.object,
};

export default connect(state => ({
    dataOriginalDecimal: state.w17f1005?.dataOriginalDecimal || {},
    dataSettingForm: state.w17f1005?.dataSettingForm || {},
}), (dispatch) => ({
    w17f1005Actions: bindActionCreators(w17f1005Actions, dispatch)
}))(W17F1005Page);
