/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19F1000Actions from "../../../actions/w19/w19F1000/w19F1000-actions";
import * as userActions from "../../../actions/user-actions";
import GridContainer from "../../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import Config from "../../../config";
import {Column} from "devextreme-react/data-grid";
import GridActionBar from "../../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
import {CheckBox} from "devextreme-react";
import PropTypes from "prop-types"
import * as w19F1001Actions from "../../../actions/w19/w19F1001/w19F1001-actions";
import * as W17F1026Actions from "../../../actions/w17F1026-actions";

import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

class W17F1026_Price extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.mode = '';

    };

    loadGrid() {
        const uName = Config.profile.UserID;
        const {CompanyID} = this.props;
        const divisionID = Config.profile.DivisionID || '';
        console.log('CompanyID', CompanyID);
        const params = {
            UserID: uName,
            DivisionID: divisionID,
            Language: Config.language || '84',
            StrSearch: this.filter.search,
            skip: this.filter.skip,
            limit: this.filter.limit,
            CompanyID: CompanyID,
        };
        this.props.w19F1000Actions.loadGrid(params, () => {

        });
    }

    componentDidMount = async () => {
        if(this.props.iPermission <= 0) return;
        this.getNumValue();
        this.loadGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.masterInputData) !== JSON.stringify(this.props.masterInputData)) {
            this.loadGrid();
        }
    }

    onRedirect =(pathname,state ={},method = "push") =>{
        const formCallState = (this.props.location && this.props.location.state) || {}
        state.formCallState = formCallState

        browserHistory[method] && browserHistory[method]({pathname,state})
    }
    onDelete(data) {
        const rowData = data.row.data;
        const param = {
            QuotationID: rowData.QuotationID,
            DivisionID: rowData.DivisionID
        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.W17F1026Actions.deleteQuotation(param, (error) => {
                if (error || !data) {
                    Config.popup.show('INFO', (error && error.message) || Config.lang("CRM_Loi_chua_xac_dinh"));
                    return false;
                }
                this.loadGrid();
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
            })
        })

    }
    onClickAddOrder =(e) =>{
        const curData = e.row.data || {};
        const params = {
            Language: Config.language || '84',
            DivisionID: curData.DivisionID || "",
            QuotationID: curData.QuotationID || "",
        };
        this.props.W17F1026Actions.checkAddOrder(params, (error, data) => {
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (data.Status === 0) {
                   //
                    const {currentTabKey, location} = this.props;
                    this.onRedirect(Config.getRootPath() + 'W17F2040', {
                        mode:         'add',
                        formCall:     'W17F1026',
                        LinkMaster:   curData.QuotationID,
                        QuotationNum: curData.VoucherNum,
                        orderTab:     true,
                        formCallState: location?.state || {},
                        currentTabKey,
                        tabName: "price"
                    });

                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message);
                }
            }
        })
    }
    onAddNew() {
         const {CompanyTypeID,CompanyID,CompanyName,masterInputData, currentTabKey, location} = this.props;
         const objectCustomer = {
             objectID: masterInputData.ObjectID ? masterInputData.ObjectID : "",
             objectName: masterInputData.CompanyNameU ? masterInputData.CompanyNameU : "",
             objectTypeID: masterInputData.CompanyTypeID ? masterInputData.CompanyTypeID : "",
             telephone: masterInputData.Telephone ? masterInputData.Telephone : "",
             paymentTermID: masterInputData.PaymentTermID ? masterInputData.PaymentTermID : "",
             divisionID: masterInputData.DivisionID ? masterInputData.DivisionID : ""
         };
         this.onRedirect(Config.getRootPath() + 'W17F2000',{
             mode: 'add',
             ObjectTypeID: CompanyTypeID,
             ObjectID: CompanyID,
             ObjectName: CompanyName,
             isLoadContactPerson: true,
             objectCustomer,
             formCall: "W17F1026",
             formCallState: location?.state || {},
             currentTabKey
         })
     };




    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    getNumValue() {
        this.props.w19F1001Actions.getNumValue((error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }

        })
    };
    //check
    checkEdit = (data) => {
        let params = {
            Language: Config.language || "84",
            DivisionID: data ? data.DivisionID : "",
            QuotationID: data ? data.DivisionID : "",
        }
        this.props.W17F1026Actions.checkEditPrice(params, (error, data) => {
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (data.Status === 0) {
                    this.onRedirect(Config.getRootPath() + 'W17F2000',{
                        mode: "edit",
                        id: params.QuotationID,
                    })

                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message);
                }
            }
        });
    }
    renderAction = (e) => {
        let per = 0;
        const {iPermission,iPermissionOrder, disabledAction} = this.props;
        if (!e) return null;
        if (e.data.AStatusID === 0) {
            per = iPermission;
        }
        return (
            <GridActionBar
                className={(!disabledAction) ? '' : 'isDisabled'}
                isPer={!disabledAction && per}
                // tooltipEdit={Config.lang('CRM_Sua')}
                // onEditAction={() => {
                //     this.checkEdit(e.row.data)
                // }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e)
                }}
                btnHamburger={[
                    {
                        name: Config.lang("CRM_Lap_don_hang"),
                        icon: 'fas fa-shopping-cart text-blue',
                        disabled: iPermissionOrder < 2,
                        className: iPermissionOrder < 2 ? 'isDisabled' : '',
                        onClick: () => {
                            if (iPermissionOrder >= 2) {
                                this.onClickAddOrder(e)
                            }
                        }
                    },
                ]}
            />
        );
    }

    render() {
        let {dataGrid, numValue, disabledAction, currentTabKey, location} = this.props;
        const {iPermission} = this.props;
        return (
            <GridContainer
                allowSearch ={disabledAction ? "isDisabled" : ""}
                disabledAddNew ={disabledAction}
                id={'W17F1026_Price'}
                dataSource={dataGrid && dataGrid}
                totalItems={dataGrid ? dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0 : 0}
                itemPerPage={this.filter.limit}
                onAddNew={() => this.onAddNew()}
                isPer={iPermission}

                onSearch={(text) => {
                    this.onSearch(text)
                }}
                onChangePage={(page) => {
                    this.onChangePage(page)
                }}
                onChangePerPage={(per) => {
                    this.onChangePerPage(per)
                }}>
                <Column
                    cellRender={(data) => this.renderAction(data)}
                    caption={'Hành động'}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={120}
                    fixed={true}
                />
                <Column
                    dataField={'DivisionID'}
                    caption={Config.lang("CRM_Don_vi_ban")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'VoucherNum'}
                    caption={Config.lang("CRM_So_bao_gia")}
                    cellRender={(e) => {
                        const {data} = e;
                        /* eslint-disable */
                        return (
                            <a onClick={() => {
                                const state = {
                                    mode:"view",
                                    id: data.QuotationID,
                                    formCall: "W17F1026",
                                    formCallState: location?.state || {},
                                    currentTabKey
                                };
                                this.onRedirect(Config.getRootPath() + 'W17F2000', state)
                            }}>{data.VoucherNum && data.VoucherNum}</a>)
                        /* eslint-enable */

                    }}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'QuotationDate'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                        return date
                    }}

                    caption={Config.lang("CRM_Ngay_bao_gia")}
                />

                <Column
                    dataField={'Amount'}
                    caption={Config.lang("CRM_Tien_hang")}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    width={120}/>
                <Column
                    dataField={'Reduce'}
                    width={120}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tien_chiet_khau")}/>
                <Column
                    dataField={'VAT'}
                    width={120}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tien_thue")}/>
                <Column
                    dataField={'TotalAmount'}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tong_tien")}
                    width={120}/>
                <Column
                    dataField={'AStatus'}
                    caption={Config.lang("CRM_Da_duyet")}
                    alignment={'center'}
                    width={120}/>
                <Column
                    dataField={'ApproveUser'}
                    caption={Config.lang("CRM_Nguoi_duyet")}
                />
                <Column
                    dataField={'ValidTo'}
                    alignment={'center'}
                    cellRender={(e) => {
                        let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                        return date
                    }}
                    caption={Config.lang("CRM_Ngay_hieu_luc")}
                    width={150}
                />
                <Column
                    dataField={'SalesPersonName'}
                    caption={Config.lang("CRM_Nguoi_bao_gia")}

                />
                <Column
                    dataField={'Status'}
                    caption={Config.lang("CRM_Da_dong")}
                    alignment={'center'}
                    width={80}
                    cellRender={(e) => {
                        return (<CheckBox
                            value={e.value === 1}
                            className={'control-label text-bold'}
                            id={"Disabled"}
                        />)
                    }}
                />
                <Column
                    dataField={'Description'}
                    caption={Config.lang("CRM_Ghi_chu")}
                />
            </GridContainer>
        )
    }

}

W17F1026_Price.propTypes = {
    objectID: PropTypes.string,
    companyID: PropTypes.string,
    iPermission: PropTypes.number,
    masterInputData: PropTypes.object,
    currentTabKey: PropTypes.number,
    location: PropTypes.object
};
export default connect(state => ({
        dataGrid: state.w19f1000.dataGrid,
        numValue: state.w19f1001.numValue
    }),
    (dispatch) => ({
        w19F1000Actions: bindActionCreators(w19F1000Actions, dispatch),
        w19F1001Actions: bindActionCreators(w19F1001Actions, dispatch),
        W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),

    })
)(W17F1026_Price);
