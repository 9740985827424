/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {FormGroup, Row, Col, InputGroup, Button} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";
import Config from '../../config/index';
import Combo from '../common/dropdown/combo';
import EventTracking from "../common/tracking/event";
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import {
    Button as ButtonDev,
    CheckBox,
    TextArea,
    TextBox,
} from 'devextreme-react';
import PropTypes from 'prop-types';
import * as w17Actions from "../../actions/w17-actions";
import * as w17f0040Actions from "../../actions/w17f0040-actions";
import * as fileActions from "../../actions/file-actions";
import getReportTypeID from "../../actions/async/w17f0041/report-type-async";
import {Column} from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";

const cssForm = {margin: '15px 15px 0 15px'};

const createCustomStore = {
    store: new CustomStore({
        key: "ReportTypeID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "search": loadOptions.searchValue,
                // "where":"ModuleID = '17' AND IsLemonWeb = 1"
                "where": JSON.stringify([
                    {
                        fieldName: 'ModuleID',
                        operator: '=',
                        value: 17
                    },
                    {
                        fieldName: 'IsLemonWeb',
                        operator: '=',
                        value: 1
                    }

                ])
            };
            if (loadOptions.filter) {
                // param.where = "ModuleID = '17' And ReportTypeNameU LIKE N'%" + loadOptions.filter.filterValue + "%'";
                param.where = JSON.stringify([
                    {
                        fieldName: 'ModuleID',
                        operator: '=',
                        value: 17
                    },
                    {
                        fieldName: 'ReportTypeNameU',
                        operator: 'LIKE',
                        value: loadOptions.filter.filterValue
                    },
                ])
            }
            const data = await getReportTypeID(param);
            const result = {
                'data': data.rows,
                'totalCount': data.total
            };
            return result;
        },
        byKey: async (key) => {
            const param = {
                // "where": "ModuleID = '17' AND IsLemonWeb = 1 AND ReportTypeID='" + key + "'"
                where: JSON.stringify([{"fieldName": "ModuleID", "operator": "=", "value": "17"},
                    {"fieldName": "IsLemonWeb", "operator": "=", "value": "1"},
                    {"fieldName": "ReportTypeID", "operator": "=", "value": key}])
            };
            const data = await getReportTypeID(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};


class W17F0041Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reportID: this.props.data.reportID,
            fileName: this.props.data.fileName,
            titleU: this.props.data.titleU,
            remark: this.props.data.remark,
            reportTypeID: this.props.data.reportTypeID,
            disabled: this.props.data.disabled,
            fileExt: this.props.data.fileExt,
            url: this.props.data.url,
            formState: this.props.formState,
            isSubmit: false
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    onEventSave() {
        this.onFormSubmit();
    }

    onSaveAdd() {
        const x = document.getElementById("file");
        const file = x.files[0];
        const fileName = Config.replaceES(file.name);
        const fileSize = file.size;
        const fileExp = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
        this.props.fileActions.upload(file, 'single', (error, res) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (res.code === 200) {
                const data = res.data;
                let param = {
                    'KeyID': this.state.reportID,
                    'Key2ID': this.state.reportTypeID,
                    'DescriptionU': "Mẫu báo cáo",
                    'NotesU': "Mẫu báo cáo",
                    'URL': data && data.length > 0 ? data[0].path : '',
                    'FileName': fileName,
                    'FileSize': fileSize,
                    'FileExt': fileExp,
                    'TableName': 'D89T1000'
                };

                this.props.w17Actions.addW17F0001(param, (error2, res2) => {
                    if (error2) {
                        let message = error2.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    if (res2 && res2.code === 200) {
                        let param2 = {
                            'ModuleID': '17',
                            'ReportTypeID': this.state.reportTypeID,
                            'ReportID': this.state.reportID,
                            'Disabled': this.state.disabled === true ? "1" : "0",
                            'TitleU': this.state.titleU,
                            'IsLW': "1",
                            'FileExt': fileExp,
                            'URL': data && data.length > 0 ? data[0].path : '',
                            'FileName': fileName,
                            'Remark': this.state.remark
                        };

                        this.props.w17f0040Actions.addW17F0041(param2, (error3, res3) => {
                            if (error3) {
                                let message = error3.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                return false;
                            }
                            if (res3 && res3.code === 200) {
                                // Show message success and close popup
                                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                                this.props.onReloadGrid();
                                // Auto close when nothing to do
                                clearTimeout(this.timer);
                                this.timer = setTimeout(() => {
                                    this.setState({formState: 'edit', isSubmit: false});
                                    this.props.onHide();
                                }, this.timeDeplay);
                            }

                        });

                    }

                });
            }


        });
    }

    onSaveEdit() {
        let param = {
            'ModuleID': '17',
            'ReportTypeID': this.state.reportTypeID,
            'ReportID': this.state.reportID,
            'Disabled': this.state.disabled === true ? "1" : "0",
            'TitleU': this.state.titleU,
            'Remark': this.state.remark
        };
        this.props.w17f0040Actions.editW17F0041(param, (error, res) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (res && res.code === 200) {
                // Show message success and close popup
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                this.props.onReloadGrid();
                // Auto close when nothing to do
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.setState({isSubmit: false});
                    this.props.onHide();
                }, this.timeDeplay);
            }

        });


    }

    onFormSubmit(e) {
        // console.log('===========onFormSubmit=============');
        // e.preventDefault();

        if (this.state.formState === 'add') {
            this.onSaveAdd();
        } else {
            this.onSaveEdit();
        }
    }

    onDownloadReport() {
        let element = document.createElement('a');
        element.setAttribute('href', this.state.url);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    onChangeInput() {
        const x = document.getElementById("file");
        const event = new MouseEvent('click');
        x.dispatchEvent(event);
    }

    checkFileType(filename) {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.xls', '.xlsx', '.doc', '.docx'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    }

    onSelectFile(e) {
        const x = document.getElementById("file");
        const arrFile = x.files;
        // console.log(arrFile);
        const sizeLimit = 50000000;
        for (let i = 0; i < arrFile.length; i++) {
            if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
                Config.popup.show("INFO", Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB');
            } else if (this.checkFileType(arrFile[i].name) === false) {
                Config.popup.show("INFO", Config.lang('CRM_Dinh_dang_file_khong_hop_le'));
            } else { //truong hop hop le

                this.setState({fileName: arrFile[i].name});
            }
        }
    }

    render() {
        let totals = 0;
        const {formState} = this.state;
        return (
            <form style={cssForm} onSubmit={(e) => this.onFormSubmit(e)}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F0041"} label={Config.profile.UserID}/>}

                <FormGroup>
                    <Row>
                        <Col sm={2}>
                            <label className="control-label">{Config.lang("CRM_Ma_bao_cao")}<span
                                style={{color: 'red'}}>*</span></label>
                        </Col>
                        <Col sm={10}>

                            <TextBox name={"ReportID"}
                                     value={this.state.reportID}
                                     disabled={this.state.formState === 'edit'}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme"
                                     maxLength={20}
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>

                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={2}>
                            <label className="control-label">{Config.lang("CRM_Mau_dinh_kem")}<span
                                style={{color: 'red'}}>*</span></label>
                        </Col>
                        <Col sm={10}>
                            <InputGroup className="mb-3">
                                <TextBox name={"FileName"}
                                         value={this.state.fileName}
                                         readOnly={true}
                                         onChange={(e) => this.onTextValueChanged(e)}
                                         className="input-devextreme">
                                    <Validator>
                                        <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                    </Validator>
                                </TextBox>
                                <InputGroup.Button>
                                    <Button disabled={this.state.formState === 'edit'} style={{height: '36px'}}
                                            onClick={() => this.onChangeInput()}><i
                                        className="far fa-stream text-blue"/></Button>
                                    {
                                        formState !== 'add' &&
                                        <Button style={{height: '36px'}} onClick={() => this.onDownloadReport()}><i
                                            className="fas fa-download text-red"/></Button>
                                    }

                                    <input type="file" id="file" name="files[]" className="hide"
                                           onChange={() => this.onSelectFile()}/>
                                </InputGroup.Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={2}>
                            <label className="control-label">{Config.lang("CRM_Ten_mau")}<span
                                style={{color: 'red'}}>*</span></label>
                        </Col>
                        <Col sm={10}>

                            <TextBox name={"TitleU"}
                                     value={this.state.titleU}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme">
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={2}>
                            <label className="control-label">{Config.lang("CRM_Loai_mau")}<span
                                style={{color: 'red'}}>*</span></label>
                        </Col>
                        <Col sm={10}>
                            <Combo
                                showHeader={false}
                                disabled={this.state.formState === 'edit'}
                                required={true}
                                virtual={true}
                                dataSource={createCustomStore}
                                placeholder={''}
                                value={this.state.reportTypeID}
                                valueExpr="ReportTypeID"
                                displayExpr="ReportTypeNameU"
                                totalItems={totals}
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeCBReportTypeID(data)
                                }}
                            >
                                <Column dataField={'ReportTypeID'} caption="" visible={true} dataType={'string'}/>
                                <Column dataField={'ReportTypeNameU'} caption="" width={'100%'}/>
                            </Combo>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={2}>
                            <label className="control-label">{Config.lang("CRM_Ghi_chu")}</label>
                        </Col>
                        <Col sm={10}>
                            <TextArea name={"Remark"}
                                      height={90}
                                      value={this.state.remark}
                                      text={this.state.remark}
                                      maxLength={this.state.remark.maxLength}
                                // valueChangeEvent={this.state.remark}
                                      onChange={(e) => this.onTextValueChanged(e)}
                                      onPaste={(e) => this.onTextValueChanged(e)}
                                      className="input-devextreme"
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={2}>
                            <CheckBox
                                width={200}
                                text={Config.lang("CRM_Ngung_hoat_dong")}
                                name={"Disabled"}
                                id={"Disabled"}
                                disabled={this.state.formState === 'add'}
                                onValueChanged={(e) => this.onDisabledChanged(e)}
                                value={!this.state.disabled || this.state.disabled === '0' ? false : true}
                            />
                        </Col>
                        <Col sm={8} className={"jus-end"}>
                            {/*<div >*/}

                            <ButtonSave onClick={() => this.onEventSave()} className={"mgr5"}
                                        name={Config.lang("CRM_Luu")}/>
                            <ButtonNotSave name={Config.lang("CRM_Khong_luu")} onClick={() => {
                                this.props.onHide()
                            }}/>
                            <ButtonDev id="btnSubmit" useSubmitBehavior={true} className="hide"/>
                        </Col>
                    </Row>
                </FormGroup>


            </form>

        )
    }

    onChangeCBReportTypeID(data) {
        this.setState({reportTypeID: data});
    }

    onTextValueChanged(e) {
        switch (e.event.target.name) {
            case "ReportID":
                let str = Config.replaceSymbol(e.event.target.value);
                this.setState({reportID: str});
                break;
            case "FileName":
                this.setState({fileName: e.event.target.value});
                break;
            case "TitleU":
                this.setState({titleU: e.event.target.value});
                break;
            case "Remark":
                this.setState({remark: e.event.target.value});
                break;
            default:
                break;
        }
    }


    onDisabledChanged(e) {
        switch (e.element.id) {
            case "Disabled":
                if (this.state.isDefault !== true)
                    this.setState({disabled: e.value});
                else
                    this.setState({disabled: e.value, isDefault: !e.value});
                break;
            default:
                break;
        }

    }
}

W17F0041Page.propTypes = {

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};


export default connect(state => ({
        loadW17F0040: state.w17f0040.loadW17F0040,
        loadW17F0041: state.w17f0040.loadW17F0041,
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
        w17f0040Actions: bindActionCreators(w17f0040Actions, dispatch)
    })
)(W17F0041Page);
