/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/W17F0040.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Config from '../../config/index';
import * as w17Actions from "../../actions/w17f0040-actions";
import * as userActions from "../../actions/user-actions";
import Detail from "../W17/W17F0041";
import EventTracking from "../common/tracking/event";
import {FormGroup, Row,Col} from 'react-bootstrap';

import GridContainer from "../common/grid-container/grid-container";
import { Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import GridActionBar from "../common/action-bar/grid-action-bar";
import removeFile from "../../actions/file-remove-async";

const cssForm = {  marginTop : '10px'};
 class W17F0040Page extends React.PureComponent {
     constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
            showPopup: false,
            formState: 'view',
            reportTypeID:[],
            row:[],
        };
         this.timer = null;
         this.filter = {
             skip: 0,
             limit: 20,
             search: ''
         };

         this.filterDeail = {
             skip: 0,
             limit: 20,
             search: ''
         };
    };

     componentDidMount = async () => {
         await this.loadPermission();
         if(this.state.iPermission <= 0) return;
         this.loadGrid();
     };

     async loadPermission() {
         await this.props.userActions.getPermission(Config.profile.UserID, "D17F0040", (iPer) => {
            this.setState({iPermission: iPer});
         });
     }

     loadGrid(){
         const param = {
             "skip" : this.filter.skip,
             "limit" : this.filter.limit,
             "search": this.filter.search,
             // "where": "ModuleID = '17' And IsLemonWeb=1"
             where:JSON.stringify([
                 {
                     fieldName:'ModuleID',
                     operator:'=',
                     value:17
                 },
                 {
                     fieldName:'IsLemonWeb',
                     operator:'=',
                     value:1
                 }
             ])
         };
         this.props.w17Actions.loadW17F0040(param, (error, res) => {
             if(error){
                 let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                 Config.popup.show('INFO',message);
                 return false;
             }

             if (res && res.data){
                 if(res.data.rows.length>0){
                     this.setState({reportTypeID:[res.data.rows[0].ReportTypeID]},()=>this.loadGridDetail());
                 }
             }
         });
     }


     loadGridDetail(){
         const param = {
             "skip" : this.filterDeail.skip,
             "limit" : this.filterDeail.limit,
             "search": this.filterDeail.search,
             // "where":"ReportTypeID = '" + this.state.reportTypeID + "'"
             "where":JSON.stringify({
                 fieldName:'ReportTypeID',
                 operator:'=',
                 value:this.state.reportTypeID
             })
         };
         this.props.w17Actions.loadW17F0040Detail(param, (error, data) => {
             if(error){
                 let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                 Config.popup.show('INFO',message);
                 return false;
             }
             // console.log("===============loadGridDetail=============",data);
         });

     }

     onRowChanged(row){
         // console.log("=============onRowChanged==============",row);
         this.setState({reportTypeID:row.selectedRowKeys},()=>this.loadGridDetail());
     }


     onChangePage(page) {
         // console.log('== onChangePage => page: ', page);
         this.filter.skip = page * this.filter.limit;
         this.loadGrid();
     }
     onChangePerPage(per) {
         // console.log('== onChangePerPage => per: ', per);
         this.filter.skip = 0;
         this.filter.limit = per;
         this.loadGrid();
     }

     onSearch(text) {
         // console.log('== onSearch => text: ', text);
         this.filter.search = text;
         this.loadGrid();
     }

     onChangePageDetail(page) {
         // console.log('== onChangePage => page: ', page);
         this.filterDeail.skip = page * this.filterDeail.limit;
         this.loadGridDetail();
     }
     onChangePerPageDetail(per) {
         // console.log('== onChangePerPage => per: ', per);
         this.filterDeail.skip = 0;
         this.filterDeail.limit = per;
         this.loadGridDetail();
     }

     onSearchDetail(text) {
         // console.log('== onSearch => text: ', text);
         this.filterDeail.search = text;
         this.loadGridDetail();
     }

     hideAddNewPopup () {
         this.setState({showPopup: false});
     }

     renderAction (e) {
         if (!e) return null;
         return (
             <GridActionBar
                 isPer={this.state.iPermission}
                 tooltipEdit={Config.lang("CRM_Sua")}
                 onEditAction={()=>{this.onEdit(e.row.data)}}

                 tooltipDelete={Config.lang("CRM_Xoa")}
                 onDeleteAction={()=>{this.onDelete(e.row.data)}}

                 tooltipDownload={Config.lang("CRM_Tap_tin")}
                 onDownloadAction={()=>{this.onDownloadReport(e.row.data)}} />
         );
     }

     onEdit(row) {
         this.setState({showPopup: true});
         let rowNew ={
             reportTypeID:row.ReportTypeID,
             reportID:row.ReportID,
             fileName:row.FileName,
             titleU: row.TitleU,
             remark:row.Remark,
             disabled:row.Disabled,
             url:row.URL
         };
         this.setState({row:rowNew,formState:'edit'});
     }

     onAddNew () {
         this.setState({showPopup: true});
         let rowNew ={
             reportTypeID:this.state.reportTypeID,
             reportID: '',
             fileName:'',
             titleU: '',
             remark:'',
             disabled:false,
         };
         this.setState({row:rowNew,formState:'add'});
     }

     onDelete (row) {
         Config.popup.show("YES_NO",Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"),()=>{
                 const param = {
                     "ReportID":row.ReportID,
                     "ReportTypeID":row.ReportTypeID
                 };

                 this.props.w17Actions.delW17F0041(param, (error, data) => {
                     if(error){
                         let message = error.message ||Config.lang("CRM_Loi_chua_xac_dinh");
                         Config.popup.show('INFO',message);
                         return false;
                     }else{
                         if (row.URL!==''){
                            if(!this.removeFileReport(row.URL)) {
                                Config.popup.show('INFO',Config.lang("CRM_Co_loi_khi_xoa_file_bao_cao"));
                                return false;
                            }
                         }
                             Config.popup.show("INFO",Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"));
                             this.loadGridDetail();
                     }
                 });

         });

     }

     async removeFileReport(url){
         let param ={'path':url};
         const resRemove = await removeFile(param);
         if (resRemove.code !== 200) {
             return false;
         }
         return true;
     }

     onDownloadReport(row) {
         let element = document.createElement('a');
         element.setAttribute('href', row.URL);
         element.style.display = 'none';
         document.body.appendChild(element);
         element.click();
         document.body.removeChild(element);
     }

    render(){
        const { loadW17F0040,loadW17F0040Detail} = this.props;
        let totals = 0;
        let totalsDetail = 0;
        let dataGrid = [];
        let dataGridDetail = [];

        if (loadW17F0040) {
            // console.log("==== Delete W17F1555=====",loadW17F0040);
                loadW17F0040.rows.map(o=>{
                    const item = {ReportTypeID: o.ReportTypeID
                        , ReportTypeNameU: o.ReportTypeNameU};
                    dataGrid.push(item);
                    return o;
                });
                totals =  parseInt(loadW17F0040.total);
        }else{
            return null;
        }

        if (loadW17F0040Detail) {
                loadW17F0040Detail.rows.map(o=>{
                    const item = {ReportID: o.ReportID
                        , ReportTypeID:o.ReportTypeID
                        , Module:o.Module
                        , TitleU: o.TitleU
                        , DisplayOrder: o.DisplayOrder
                        , CreateUserID: o.CreateUserID
                        , CreateDate: o.CreateDate
                        , LastModifyUserID: o.LastModifyUserID
                        , LastModifyDate: o.LastModifyDate
                        , Remark: o.Remark
                        , FileExt:o.FileExt
                        , FileName:o.FileName
                        // , URL:o.URL
                        , URL: Config.env.api + '/file/get-report?ReportID=' + o.ReportID  + '&ReportTypeID=' +o.ReportTypeID
                        , Disabled: o.Disabled !== 0
                    };
                    dataGridDetail.push(item);
                    return o;
                });
                totalsDetail =  parseInt(loadW17F0040Detail.total);

        }else{
            return null;
        }
        return(
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W17F0040"} label={Config.profile.UserID} /> }

                <div className="lb-cus-title">{Config.lang("CRM_Thiet_lap_mau_bao_cao")}</div>
                <FormGroup style={cssForm}>
                    <Row style={{marginRight:0}}>
                        <Col sm={3}>
                            <GridContainer
                                title={""}
                                dataSource={dataGrid}
                                keyExpr={'ReportTypeID'}
                                selectedRowKey={this.state.reportTypeID}
                                showBorders={false}
                                showColumnLines={true}
                                selection={{ mode: 'single' }}
                                allowColumnReordering={true}
                                rowAlternationEnabled={true}
                                totalItems={totals}
                                itemPerPage={this.filter.limit}
                                typeShort={true}
                                onSearch={(text) => {this.onSearch(text)}}
                                onChangePage={(page) => {this.onChangePage(page)}}
                                onChangePerPage={(per) => {this.onChangePerPage(per)}}
                                onSelectionChanged={(row) => {this.onRowChanged(row)}}

                            >
                                <Column dataField={'ReportTypeID'} caption=""   visible={false}   dataType={'string'} />
                                <Column dataField={'ReportTypeNameU'} caption={Config.lang("CRM_Loai_mau")}   width= {'100%'} />
                            </GridContainer>
                        </Col>

                        <Col sm={9}>
                            <GridContainer
                                title={""}
                                isPer={this.state.iPermission}
                                dataSource={dataGridDetail}
                                keyExpr={'ReportID'}
                                showBorders={false}
                                showColumnLines={true}
                                selection={{ mode: 'single' }}
                                allowColumnReordering={true}
                                rowAlternationEnabled={true}
                                onAddNew={() => {this.onAddNew()}}
                                totalItems={totalsDetail}
                                itemPerPage={this.filter.limit}
                                onSearch={(text) => {this.onSearchDetail(text)}}
                                onChangePage={(page) => {this.onChangePageDetail(page)}}
                                onChangePerPage={(per) => {this.onChangePerPageDetail(per)}}
                            >

                                <Column dataField={'ReportTypeID'} caption=""   visible={false}   dataType={'string'} />
                                <Column dataField={'ReportID'} caption=""   visible={false}   dataType={'string'} />
                                <Column dataField={'TitleU'} caption={Config.lang("CRM_Ten_mau")}    width= {300} />
                                <Column dataField={'FileExt'} caption="Ext"   width= {100} alignment={'center'} />
                                <Column dataField={'Remark'} caption={Config.lang("CRM_Ghi_chu")} minWidth={300}    width= {"100%"} />
                                <Column dataField={'LastModifyUserID'} caption={Config.lang("CRM_Nguoi_sua")}  width={150}   dataType={'string'}/>
                                <Column dataField={'LastModifyDate'}  caption={Config.lang("CRM_Ngay_sua")}  width= {150} dataType={'date'}   format={'dd-MM-yyyy'} alignment={'center'} />
                                <Column dataField={'Disabled'} caption={Config.lang("CRM_KSD")}  width={100}  dataType={'boolean'} />
                                <Column
                                    cellRender={(data)=>this.renderAction(data)}
                                    dataField={'Action'}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={120}
                                    fixed={true}
                                />
                            </GridContainer>
                        </Col>

                    </Row>

                </FormGroup>
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Cap_nhat_mau_bao_cao")}
                    show={ true }
                    animation={true}
                    onHide={() => this.hideAddNewPopup()} >
                                    <Detail data={this.state.row} formState={this.state.formState} onHide={() => this.hideAddNewPopup()} onReloadGrid={()=>this.loadGridDetail()}/>
                </PopupView>}

            </div>
        )
    }
}

export default connect(state => ({
        loadW17F0040: state.w17f0040.loadW17F0040,
        loadW17F0041: state.w17f0040.loadW17F0041,
        loadW17F0040Detail: state.w17f0040.loadW17F0040Detail,
        delW17F0041: state.w17f0040.delW17F0041
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F0040Page);