/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../../common/grid-container/grid-container";
import Config from "../../../config";
import {Column} from "devextreme-react/data-grid";
import * as w17f1026Actions from "../../../actions/w17F1026-actions";
import * as w18f1060Actions from "../../../actions/w18f1060-actions";
import * as userActions from "../../../actions/user-actions";
import {browserHistory} from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/vi'
import GridActionBar from "../../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
moment.locale('vi');

class W17F1026_Case extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: "",
            UserID: Config.profile.UserID || "",
            Language: Config.language || "84",
            ReportID: "",
            CompanyNameU: "",
            SalesPersonID: "",
            HandlerID: "",
            CaseTypeID: "",
            CaseStatus: "",
            MediaID: "",
            DateFrom: null,
            DateTo: null,
            ReportTypeID: "",
            CaseID: "",
            TypeID: "LoadGrid_F1026",
        };
        this.totalCaseTab = 0;
    };

    /**
     * @Load..
     */
    loadGrid() {
        const { Language, UserID, ReportID, limit, skip, search: StrSearch, CompanyNameU, SalesPersonID, HandlerID, CaseTypeID, CaseStatus, MediaID, DateFrom, DateTo, ReportTypeID, CaseID, TypeID  } = this.filter;
        const { companyID: CompanyID = ""} = this.props;
        const param = {
            UserID,
            ReportID,
            Language,
            limit,
            skip,
            CompanyID,
            CompanyNameU,
            StrSearch,
            SalesPersonID,
            HandlerID,
            CaseTypeID,
            CaseStatus,
            MediaID,
            DateFrom,
            DateTo,
            ReportTypeID,
            CaseID,
            TypeID,
        };

        this.props.w17f1026Actions.loadCaseTabW17F1026(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data) {
                this.totalCaseTab = data.total;
            }
        });

    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.masterInputData) !== JSON.stringify(this.props.masterInputData)) {
            if (this.props.iPermission <= 0) return;
            this.loadGrid();
        }
    }

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSearch = (text) => {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    };

    /**
     * @Actions
     */
    //Case Tab...
    onAdd = () => {
        const { permission, companyID, CompanyTypeID, companyName, telephone, email, address, currentTabKey, location } = this.props;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: {
                mode: 'add', permission: permission, companyID, companyTypeID: CompanyTypeID, companyName: companyName, telephone: telephone,
                email: email, address: address, FormID: 'W17F1026', currentTabKey,
                formCallState: location ? location.state : {}
            }
        });
    };

    onEdit = (data) => {
        const { CaseCode = "", CaseID = ""} = data;
        const {permission, currentTabKey,companyID,location} = this.props;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: { mode: 'edit', CaseCode, permission, CaseID, currentTabKey, FormID: 'W17F1026', companyID, formCallState: location ? location.state : {} }
        });
    };

    onView = (data) => {
        const {currentTabKey,companyID,location} = this.props;
        const { CaseCode = "", CaseID = ""} = data;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: { mode: 'view', CaseCode, CaseID, currentTabKey, FormID: 'W17F1026', companyID, formCallState: location ? location.state : {} }
        });
    };

    onDelete = (data) => {
        let param = {
            caseID: data.CaseID
        };
        if (data.CaseStatus === "FINISHED") {
            Config.popup.show("INFO", Config.lang("CRM_Du_lieu_da_duoc_su_dung"))
        } else {
            Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                this.props.w18f1060Actions.deleteRowData(param, async (error) => {
                    if (error) {
                        return false;
                    } else {
                        const historyStatus= await this.saveHistory(data.CaseID);
                        if (historyStatus !== 200) {
                            Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                            return false;
                        }
                        notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
                        this.loadGrid();
                    }
                })
            })
        }
    };

    saveHistory = async (CaseID) => {
        let el={};
        let data=[];
        let status = '';
        el.codeID =CaseID;
        el.formID = 'W18F1060';
        el.linkedTrans = '';
        el.oldValue = CaseID;
        el.newValue ='';
        el.description84 = Config.lang("Danh_sach_tinh_huong",'vi');
        el.description01 =Config.lang("Danh_sach_tinh_huong",'en');
        el.action = 2;
        data.push(el);
        const param = {
            attributes: JSON.stringify(data)
        };
        await this.props.w18f1060Actions.addHistory(param, (error) => {
            status = error ? 400 : 200
        });
        return status
    };

    /**
     * @Render
     */
    avatarUrlW18F1060 = (rowData) => {
        const userImage = rowData.data.URL ? rowData.data.URL : require('../../../images/icon-user-default.png');
        return (
            <div>
                <img className="img-circle mgr5" height={50} width={50} src={userImage} alt=""/>
                {rowData.data.SalesPersonID}
            </div>

        )
    };

    actionRenderW18F1060 = (e) => {
        if (!e) return null;
        const {iPermission, allowAdding} = this.props;
        return (
            <GridActionBar
                isPer={iPermission}
                classNameIs={allowAdding}
                className={allowAdding >= 4 ? '' : 'isDisabled'}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.onEdit(e.row.data);
                }}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    this.onDelete(e.row.data)
                }}
            />
        )
    };

    caseNameRenderW18F1060 = (e) => {
        if (!e) return null;
        const data = e.data;
        /* eslint-disable */
        return (
            <a title={ e.value } onClick={() => { this.onView(data) }}>
                { e.value }
            </a>
        )
        /* eslint-enable */
    };

    render() {
        const {allowAdding, iPermission, dataGridCase, disabledAction,} = this.props;
        const dataSource = dataGridCase.rows;
        return (
            <GridContainer
                allowSearch ={disabledAction ? "isDisabled" : ""}
                disabledAddNew ={disabledAction}
                allowAdding={allowAdding}
                isPer={iPermission}
                itemPerPage={this.filter.limit}
                totalItems={Number(this.totalCaseTab)}
                dataSource={dataSource}
                onAddNew={this.onAdd}
                onSearch={this.onSearch}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}>
                <Column
                    cellRender={(data) => this.actionRenderW18F1060(data)}
                    caption={Config.lang("CRM_Hanh_dong")}
                    width={100}
                    fixed={true}
                />
                <Column
                    cellRender={(rowData) => this.avatarUrlW18F1060(rowData)}
                    caption={Config.lang("CRM_Nguoi_lap")}
                    width={200}
                />
                <Column
                    cellRender={this.caseNameRenderW18F1060}
                    dataField={'CaseName'}
                    caption={Config.lang("CRM_Ten_tinh_huong")}
                />
                <Column
                    dataField={'CaseTypeName'}
                    caption={Config.lang("CRM_Loai_tinh_huong")}
                />
                <Column
                    dataField={'CaseDate'}
                    caption={Config.lang("CRM_Ngay_phat_sinh")}
                    dataType={'date'}
                    format={'dd/MM/yyyy'}
                    alignment={'center'}
                />
                <Column
                    dataField={'Description'}
                    caption={Config.lang("CRM_Dien_giai")}
                    width={150}
                />
                <Column
                    dataField={'CaseID'}
                    visible={false}
                />
                <Column
                    dataField={'CaseStatus'}
                    visible={false}
                />
                <Column
                    dataField={'CaseCode'}
                    caption={Config.lang("CRM_Ma_tinh_huong")}
                />
            </GridContainer>
        )
    }
}

W17F1026_Case.propTypes = {
    companyID: PropTypes.string,
    CompanyTypeID: PropTypes.string,
    mode: PropTypes.string,
};
export default connect((state) => ({
        dataGridCase: state.w17f1026.dataGridCase
    }), (dispatch) => ({
        w17f1026Actions: bindActionCreators(w17f1026Actions, dispatch),
        w18f1060Actions: bindActionCreators(w18f1060Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F1026_Case);
