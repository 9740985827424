/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../../common/grid-container/grid-container";
import Config from "../../../config";
import {Column} from "devextreme-react/data-grid";
import * as w19f1020Actions from "../../../actions/w19/w19f1020/w19f1020-actions";
import * as userActions from "../../../actions/user-actions";
import {browserHistory} from "react-router";
import PropTypes from "prop-types";
import * as W17F1026Actions from "../../../actions/w17F1026-actions";
import notify from 'devextreme/ui/notify';

import moment from "moment";
import 'moment/locale/vi'
import GridActionBar from '../../common/action-bar/grid-action-bar';
import { CheckBox } from 'devextreme-react';

moment.locale('vi');

class W17F1026_Order extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
    };

    loadGrid() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const divisionID = Config.CompanyID() || '';
        const {CompanyID} = this.props || '';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "DivisionID": divisionID,
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "CompanyID": CompanyID,
        };

        this.props.w19f1020Actions.dataGridW19F1020(params, (error) => {
            if (error) {
                const errorCode = error.code || null;
                let message = "";
                if (errorCode) {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });

    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.masterInputData) !== JSON.stringify(this.props.masterInputData)) {
            if (this.props.iPermission <= 0) return;
            this.loadGrid();
        }
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }
    onRedirect =(pathname,state ={},method = "push") =>{
        const formCallState = (this.props.location && this.props.location.state) || {}
        state.formCallState = formCallState

        browserHistory[method] && browserHistory[method]({pathname,state})
    }
    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    renderVoucherNum(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailVoucher(data.data)}>{name}</a></div>
        );
        /* eslint-disable */
    }

    onDetailVoucher = e => {
        if (e && e.QuotationID) {
            const {location, currentTabKey} = this.props;
            this.onRedirect(Config.getRootPath() + 'W17F2040',{
                QuotationID: e.QuotationID,
                LinkMaster : e.LinkMaster ,
                mode:"view",
                formCall: "W17F1026",
                formCallState: location?.state || {},
                currentTabKey
            })

        }
    };
    onClickAddNew =() =>{
        const {CompanyTypeID,CompanyID,CompanyName,masterInputData, location, currentTabKey} = this.props;
        const objectCustomer = {
            objectID: masterInputData.ObjectID ? masterInputData.ObjectID : "",
            objectName: masterInputData.CompanyNameU ? masterInputData.CompanyNameU : "",
            objectTypeID: masterInputData.CompanyTypeID ? masterInputData.CompanyTypeID : "",
            telephone: masterInputData.Telephone ? masterInputData.Telephone : "",
            paymentTermID: masterInputData.PaymentTermID ? masterInputData.PaymentTermID : "",
            divisionID: masterInputData.DivisionID ? masterInputData.DivisionID : ""
        };
        this.onRedirect(Config.getRootPath() + 'W17F2040',{
            mode: 'add',
            ObjectTypeID: CompanyTypeID,
            ObjectID: CompanyID,
            ObjectName: CompanyName,
            objectCustomer,
            isLoadContactPerson: true,
            formCall: "W17F1026",
            formCallState: location?.state || {},
            currentTabKey
        })

    }
    onClickEdit = (e) =>{
        const data = e.row.data || {};
        const param = {
            Language: Config.language || '84',
            QuotationID: data.QuotationID || "",
            DivisionID: data.DivisionID || ""

        };
        this.props.W17F1026Actions.checkEditOrder(param,(error,data) =>{
            if (error) {

                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (!data) {
                let message =  Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            data.Status = 0
            if (data.Status === 0) {
                this.onRedirect(Config.getRootPath() + 'W17F2040',{
                    QuotationID: param.QuotationID,
                    LinkMaster : param.LinkMaster ,
                    mode:"edit",
                    formCall : 'W17F1026',
                })


                return false
            }
            Config.popup.show('INFO', data.Message);
        })
    }


    onClickDelete = (data) =>{
        const rowData = data.row.data || {};
        const param = {
            Language: Config.language || '84',
            QuotationID: rowData.QuotationID || "",
            DivisionID: rowData.DivisionID || ""

        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.W17F1026Actions.deleteOrder(param, (error,data) => {
                if (error) {

                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }

                if (!data) {
                    let message =  Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if ( data.Status === 0) {
                    notify( data.Message || Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
                    this.loadGrid();

                }else{
                    Config.popup.show('INFO', data.Message);
                }




            })
        })

    }
    renderAction = (e, disabledAction) => {
        if (!e) return null;
        const {iPermission} = this.props;
        return (
            <GridActionBar
                isPer={!disabledAction && iPermission}
                classNameIs={iPermission}
                className={(iPermission >= 4 && !disabledAction) ? '' : 'isDisabled'}
                // tooltipEdit={Config.lang("CRM_Sua")}
                // onEditAction={() => {
                //     this.onClickEdit(e)
                // }}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onClickDelete(e)
                }}

            />
        );
    }
    render() {
        let {dataGrid, disabledAction,} = this.props;
        const {iPermission} = this.props;
        return (
            <GridContainer
                allowSearch ={disabledAction ? "isDisabled" : ""}
                disabledAddNew ={disabledAction}
                id={'W17F1026_Order'}
                dataSource={dataGrid && dataGrid}
                totalItems={dataGrid ? dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0 : 0}
                itemPerPage={this.filter.limit}
                isPer={iPermission}
                columnResizingMode={'widget'}
                onAddNew = {()=> this.onClickAddNew()}
                onSearch={(text) => {
                    this.onSearch(text)
                }}
                onChangePage={(page) => {
                    this.onChangePage(page)
                }}
                onChangePerPage={(per) => {
                    this.onChangePerPage(per)
                }}>
                <Column
                    cellRender={(data) => this.renderAction(data, disabledAction)}
                    dataField={'Action'}
                    caption={Config.lang("CRM_Hanh_dong")}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={120}
                    fixed={true}
                />
                <Column
                    cellRender={(data) => data.rowIndex + 1}
                    caption={Config.lang("CRM_STT")}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={80}
                />
                <Column
                    dataField={'DivisionID'}
                    caption={Config.lang("CRM_Don_vi_ban")}
                    width={150}
                />
                <Column
                    dataField={'VoucherNum'}
                    caption={Config.lang("CRM_So_don_hang")}
                    cellRender={(data) => this.renderVoucherNum(data)}
                    width={200}
                />
                <Column
                    dataField={'QuotationDate'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        return e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                    }}
                    caption={Config.lang("CRM_Ngay_lap")}
                />
                <Column
                    dataField={'ValidFrom'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        return e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                    }}
                    caption={Config.lang("CRM_Ngay_hieu_luc")}
                />
                <Column
                    dataField={'ValidTo'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        return e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                    }}
                    caption={Config.lang("CRM_Ngay_het_hieu_luc")}
                />

                <Column
                    dataField={'SalesPersonName'}
                    caption={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                    alignment={'left'}
                    width={200}/>
                <Column
                    dataField={'DescriptionU'}
                    caption={Config.lang("CRM_Ghi_chu")}
                    alignment={'left'}
                    width={400}/>
                <Column
                    dataField={'StatusName'}
                    width={300}
                    alignment={'left'}
                    caption={Config.lang("CRM_Trang_thai")}/>
                <Column
                    dataField={'StatusVoucher'}
                    caption={Config.lang("CRM_Trang_thai_thuc_hien_DH")}
                    alignment={'left'}
                    width={300}/>
                <Column
                    dataField={'Status'}
                    caption={Config.lang("CRM_Da_co_mat_hang")}
                    alignment={'center'}
                    width={120}
                    cellRender={(e) => {
                        return (<CheckBox
                            value={e.value === 1}
                            className={'control-label text-bold'}
                            id={"Disabled"}
                        />)
                    }}
                />
                <Column
                    dataField={'QuotationNum'}
                    caption={Config.lang("CRM_So_bao_gia")}
                    alignment={'left'}
                    width={300}/>


            </GridContainer>
        )
    }

}

W17F1026_Order.propTypes = {
    companyID: PropTypes.string,
    iPermission: PropTypes.number,
    masterInputData: PropTypes.object,
    currentTabKey: PropTypes.number,
    location: PropTypes.object
};
export default connect(state => ({
        dataGrid: state.w19f1020.dataGrid,

    }),
    (dispatch) => ({
        w19f1020Actions: bindActionCreators(w19f1020Actions, dispatch),
        W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F1026_Order);
