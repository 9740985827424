/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import Config from "../../../config";
import {Column} from "devextreme-react/data-grid";
import GridActionBar from "../../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
import {CheckBox} from "devextreme-react";
import PropTypes from "prop-types"
import * as W17F1026Actions from "../../../actions/w17F1026-actions";
import W17F2020Popup from "../W17F2020"
import Link from '../../common/link/link';

import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

class W17F1026_Request extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.mode = '';
        this.state ={
            showW17F2020Popup:false,
            W17F2020Data:{}
        }
    };

    loadGrid() {
        const {CompanyID,CompanyTypeID} = this.props;
        const params = {
            Language: Config.language || '84',
            StrSearch: this.filter.search,
            skip: this.filter.skip,
            limit: this.filter.limit,
            CompanyID: CompanyID || "",
            CompanyTypeID: CompanyTypeID || "",
        };
        this.props.W17F1026Actions.getRequestGrid(params, () => {

        });
    }

    componentDidMount = async () => {
        if(this.props.iPermission <= 0) return;
        this.loadGrid();
    };

    componentDidUpdate = (pre, next) => {
        if (pre.CompanyID !== this.props.CompanyID) {
            if (this.props.iPermission <= 0) return;
            this.loadGrid();
        }
    }
    
    _confirmDelete =(params) =>{
        this.props.W17F1026Actions.confirmDeleteRequest(params,(error,data) =>{
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ loading: false});
                return false;
            } 
            if (data.Status === 0) {
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                this.loadGrid();
                return false
            }
        })
    }
    onDelete(data) {
        const rowData = data.row.data;
        const params = {
            RequestID: rowData.RequestID
        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.W17F1026Actions.deleteRequest(params, (error,data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    this.setState({ loading: false});
                    return false;
                } 
                if (data.Status === 0) {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGrid();
                    return false
                }
                if (data.MsgAsk === 0) {
                    this.setState({ loading: false});
                    Config.popup.show("INFO", data.Message);
                    
                    return false
                }
                Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                    this._confirmDelete({code:data.code})
                },()=>{
                    this.setState({ loading: false});
                    Config.popup.show("INFO", data.Message)
                });
               
                    
                    
                
                
            });
        })

    }
    onClickUpdateStatus = e =>{
        const curData = e.row.data || {};
        this.setState({showW17F2020Popup:true,W17F2020Data:curData || {}})
    }
    onClickAddQuotation =(e) =>{
        const curData = e.row.data || {};
        const params = {
            Language: Config.language || '84',
            DivisionID: Config.profile.DivisionID || "",
            RequestID: curData.RequestID || "",
        };
        this.props.W17F1026Actions.checkAddQuotation(params, (error, data) => {
            if (error || !data ) {
                const message =( error && error.message) || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } 
            if (data.Status === 0) {
                this.onRedirect(Config.getRootPath() + 'W17F2000', {
                     mode: 'add',
                     id: curData.QuotationID || "",
                     RequestID: curData.RequestID || "",
                     isTabRequest: true,
                     isLoadForm: true,
                     ObjectTypeID: curData.ObjectTypeID,
                     ObjectID: curData.ObjectID,
                     ObjectName: curData.ObjectName,
                 })
               
             } else if (data.Status === 1 && data.Message) {
                 Config.popup.show("INFO", data.Message);
             }
                
            
        })
    }
    onAddNew() {
        const { CompanyTypeID, CompanyID, CompanyName, currentTabKey, location } = this.props;
        this.onRedirect(Config.getRootPath() + 'W17F2025', {
            mode: 'add',
            CompanyTypeID,
            CompanyID,
            CompanyName,
            formCall: 'W17F1026',
            CallFrom: "RequestTab",
            currentTabKey,
            formCallState: location ? location.state : {},
        });
        
    };


    onRedirect =(pathname,state ={},method = "push") =>{
        const formCallState = (this.props.location && this.props.location.state) || {}
        state.formCallState = formCallState
        browserHistory[method] && browserHistory[method]({pathname,state})    
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    //check 
    checkEdit = (data) => {
        const { CompanyTypeID, CompanyID, CompanyName, currentTabKey } = this.props;
        const { location } = this.props
        let params = {
            Language: Config.language || "84",
            RequestID: data ? data.RequestID : "",
        }
        this.props.W17F1026Actions.checkEditRequest(params, (error, data) => {
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
               
                return Config.popup.show('INFO', message);
            }
            if (data.Status === 0) {
                this.onRedirect(Config.getRootPath() + 'W17F2025',{
                    mode: "edit",
                    RequestID: params.RequestID,
                    currentTabKey,
                    CompanyTypeID,
                    CompanyID,
                    CompanyName,
                    formCall:"W17F1026",
                    formCallState:location ? location.state :{},
                })
                return false
            }
            if (data.MsgAsk  === 0) {
                Config.popup.show('INFO', data.Message);
                return false
            }
            Config.popup.show('YES_NO', Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"),()=>{
                this.onRedirect(Config.getRootPath() + 'W17F2025',{
                    mode: "edit",
                    RequestID : params.RequestID ,
                })
            },()=>{
                Config.popup.show('INFO', data.Message);
            });
            
            
        });
    }
    renderAction = (e) => {
        const {iPermission, disabledAction,iPermissionPrice} = this.props;
        if (!e) return null;
        
        return (
            <GridActionBar
                className={(!disabledAction) ? '' : 'isDisabled'}
                isPer={!disabledAction && iPermission}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.checkEdit(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e)
                }}
                btnHamburger={[
                    {
                        name: Config.lang("CRM_Cap_nhat_trang_thai"),
                        //icon: 'fas fa-shopping-cart text-blue',
                        disabled:disabledAction || iPermission < 3,
                        className:disabledAction || iPermission < 3 ? 'isDisabled' : '',
                        onClick: () => {
                            if (!disabledAction &&iPermission >= 3) {
                                this.onClickUpdateStatus(e)
                            }
                        }
                    },
                    {
                        name: Config.lang("CRM_Lap_bao_gia"),
                        //icon: 'fas fa-shopping-cart text-blue',
                        disabled: disabledAction || iPermissionPrice < 2,
                        className: disabledAction || iPermissionPrice < 2 ? 'isDisabled' : '',
                        onClick: () => {
                            if (!disabledAction && iPermissionPrice >= 2) {
                                this.onClickAddQuotation(e)
                            }
                        }
                    },
                ]}
            />
        );
    }

    render() {
        let { dataGrid, disabledAction, iPermission, CompanyTypeID, currentTabKey, CompanyID, CompanyName, location } = this.props;
        const {W17F2020Data,showW17F2020Popup} = this.state;
        return (<>
        {
            showW17F2020Popup && <W17F2020Popup 
                voucherID ={W17F2020Data.RequestID}
                type="UpStatusRequest"
                formID="W17F2025"
                onHide ={(reLoadStatus) => {
                    if (reLoadStatus) {
                        this.loadGrid()
                    }
                    this.setState({showW17F2020Popup:false})
                }}
                />
        }
            <GridContainer
                allowSearch ={disabledAction ? "isDisabled" : ""}
                disabledAddNew ={disabledAction}
                id={'W17F1026_Price'}
                dataSource={dataGrid && dataGrid.rows}
                totalItems={(dataGrid && dataGrid.total) || 0}
                itemPerPage={this.filter.limit}
                onAddNew={() => this.onAddNew()}
                isPer={iPermission}

                onSearch={(text) => {
                    this.onSearch(text)
                }}
                onChangePage={(page) => {
                    this.onChangePage(page)
                }}
                onChangePerPage={(per) => {
                    this.onChangePerPage(per)
                }}>
                <Column
                    cellRender={(data) => this.renderAction(data)}
                    caption={'Hành động'}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={120}
                    fixed={true}
                />
               <Column
                    dataField={'RequestID'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        if (!e) return null;
                        const RequestID = e && e.value ? e.value : '';
                        /* eslint-disable */
                        
                        return (
                            <Link getData={() =>({
                                pathname:Config.getRootPath() + 'W17F2025',
                                state:{
                                    mode: "view",
                                    RequestID,
                                    currentTabKey,
                                    CompanyTypeID,
                                    CompanyID,
                                    CompanyName,
                                    formCall: "W17F1026",
                                    formCallState: location ? location.state : {},
                                }
                            })}>{RequestID}</Link>
                        );
                        /* eslint-enable */
                    }}

                    caption={Config.lang("CRM_Ma_nhu_cau")}
                />
               <Column
                    dataField={'StatusName'}
                    alignment={'center'}
                    width={150}
                    

                    caption={Config.lang("CRM_Trang_thai_thuc_hien")}
                />
               <Column
                    dataField={'RequestDate'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                        return date
                    }}

                    caption={Config.lang("CRM_Ngay_lap")}
                />

                <Column
                    dataField={'EmployeeID'}
                    caption={Config.lang("CRM_Ma_NVKD")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'EmployeeName'}
                    caption={Config.lang("CRM_Ten_NVKD")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'ObjectID'}
                    caption={Config.lang("CRM_Ma_khach_hang")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'ObjectName'}
                    caption={Config.lang("CRM_Ten_khach_hang")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'CaseSourceName'}
                    caption={Config.lang("CRM_Nguon_goc")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'IsCollaborator'}
                    caption={Config.lang("CRM_La_cong_tac_vien")}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        return (<CheckBox
                            value={e.value === 1}
                            className={'control-label text-bold'}
                            id={"Disabled"}
                        />)
                    }}
                />
                <Column
                    dataField={'CollaboratorID'}
                    caption={Config.lang("CRM_Ma_CTV")}
                    allowSorting={true}
                    width={150}
                />
                <Column
                    dataField={'CollaboratorName'}
                    caption={Config.lang("CRM_Ten_CTV")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'Description'}
                    caption={Config.lang("CRM_Dien_giai")}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'QuatationNum'}
                    caption={Config.lang("CRM_So_bao_gia")}
                    allowSorting={true}
                    width={180}
                />
                
                
                
            </GridContainer>
        </>)
    }

}

W17F1026_Request.propTypes = {
    CompanyID: PropTypes.string,
    CompanyTypeID: PropTypes.string,
    CompanyName: PropTypes.string,
    iPermission: PropTypes.number,
    iPermissionPrice: PropTypes.number,

};
export default connect(state => ({
        dataGrid: state.w17f1026.getRequestGrid,
    }),
    (dispatch) => ({
        W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
        
    })
)(W17F1026_Request);
