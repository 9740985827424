/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/25/03 10:30
 * @update 2019/25/03 10:30
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w17f0010Actions from "../../actions/w17f0010-actions";
import * as userActions from "../../actions/user-actions";
import EventTracking from "../common/tracking/event";
import TreeList, {
    Column,
    HeaderFilter,
    Selection,
} from 'devextreme-react/tree-list';
import FieldRequired from "../common/system-settings-container/field-required";
import CustomerSetting from "../common/system-settings-container/customer-setting";

import Combo from "../common/dropdown/combo";
import notify from 'devextreme/ui/notify';
import Config from '../../config';
import ButtonSave from "../common/button/button-save";
import { Row, Col } from 'diginet-core-ui/components';
import { useTheme } from "diginet-core-ui/theme";

const { spacing } = useTheme();
class W17F0010Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            toggle: 'requiredField',
            iPermission: 0,
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 10,
            search: ''
        };
        this.CbCodeType = [];
        this.codeType = '';
        this.dataSource = [];
        this.totalItems = 0;
        this.hasChangeData = false;
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.comboCodeType();
        this.loadDataGrid();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F0001",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    comboCodeType() {
        const uLanguage = Config.language || '84';
        let typeParam = {
            "language": uLanguage
        };
        this.props.w17f0010Actions.loadFieldRequiredW17F0010(typeParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else {
                if (data) {
                    this.CbCodeType = data.map(o => {
                        const codeType = {
                            CodeType: o.CodeType
                            , Name: o.Name
                        };
                        return codeType;
                    });
                    const defaultValue = data.filter((row) => {
                        return row.CodeType;
                    });
                    if (defaultValue.length > 0) {
                        this.setState({ codeType: defaultValue[0].CodeType }, () => this.loadDataGrid());
                    }
                } else {
                    return false;
                }
            }
        });
    }

    loadDataGrid() {
        let param = {
            "codeType": this.state.codeType,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };
        this.props.w17f0010Actions.loadW17F0010(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data.rows.length > 0) {
                this.dataSource = data.rows.map(o => {
                    const groupSales = {
                        CodeType: o.CodeType,
                        Description01: o.Description01,
                        Description84: o.Description84,
                        FieldName: o.FieldName,
                        LastModifyDate: o.LastModifyDate,
                        LastModifyUserID: o.LastModifyUserID,
                        OrderNo: o.OrderNo,
                        ValidMode: o.ValidMode,
                        IsUpdate: 0,
                    };
                    return groupSales;
                });
                this.totalItems = data.total;
                this.CodeType = data.rows.CodeType;
            } else {
                return false;
            }

        });
    }

    onChangeComboCodeType(data) {
        if (this.state.codeType !== data.CodeType) {
            this.checkHasUpdate(() => {
                this.setState({
                    codeType: data.CodeType
                }, () => {
                    this.loadDataGrid();
                });
            });
        }
    }

    updateChanged(m) {
        let rowUpdate = this.dataSource.filter(row => {
            return row.CodeType === m.CodeType && row.FieldName === m.FieldName;
        });
        if (rowUpdate) {
            rowUpdate[0].IsUpdate = 1;
        }
    }

    async onEventSave() {
        const gridSelectedRowKeys = [...this.dataSource];
        let grid = [];
        gridSelectedRowKeys.forEach((value, index) => {
            if (value.IsUpdate === 1) {
                grid.push(value);
            }
        });
        if (grid.length <= 0) {
            Config.popup.show('INFO', `${Config.lang("CRM_Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap")}`);
            return false;
        }
        const paramValidMode = {
            param: JSON.stringify(grid)
        }
        this.props.w17f0010Actions.editW17F0010(paramValidMode, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({isSubmit: false});
                Config.popup.show("INFO", message);
                return false;
            }
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
            this.loadDataGrid();
        });
    }

    selectedRow(e) {
        this.setState({
            toggle: e.data.ID
        })
    }

    setDefaultRow(e) {
        e.component.selectRows(this.state.toggle);
    }

    checkHasUpdate(cb) {
        let rowUpdate = this.dataSource.filter(row => {
            return row.IsUpdate === 1;
        });
        if (rowUpdate && rowUpdate.length > 0) {
            Config.popup.show("YES_NO", `${Config.lang("CRM_Du_lieu_co_thay_doi_ban_co_muon_luu_khong")}`, () => {
                this.onEventSave();
                cb && cb();
                return;
            });
            return;
        }
        cb && cb();
    }


    onChangePage(page) {
        this.checkHasUpdate(() => {
            this.filter.skip = page * this.filter.limit;
            this.loadDataGrid();
        });

    }

    onChangePerPage(per) {
        this.checkHasUpdate(() => {
            this.filter.limit = per;
            this.loadDataGrid();
        });
    }

    onCheckUpdateField() {
        this.hasChangeData = true;
        // console.log("====onCheckUpdateField==== this.hasChangeData :", this.hasChangeData);
    }

    render() {
        const { iPermission, codeType } = this.state;
        if (iPermission === 0) return null;
        const products = [{
            ID: 'root',
            name: Config.lang("Tuy_chon_cong_ty"),
            expanded: true
        }, {
            ID: 'requiredField',
            categoryId: 'root',
            name: Config.lang("Thiet_lap_truong_bat_buoc"),
            expanded: true
        },
        {
            ID: 'customerSetting',
            categoryId: 'root',
            name: Config.lang("Thiet_lap_khach_hang_chung"),
            expanded: true
        }
        ];
        return (
            <div className="page-container" id={'clRequiredField'}>
                {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W17F0010"} label={Config.profile.UserID} />}

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="grid-title mgb5">{Config.lang("CRM_Thiet_lap_he_thong")}</div>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} style={{ marginTop: spacing(4) }}>
                        <TreeList
                            showBorders={true}
                            columnAutoWidth={false}
                            wordWrapEnabled={true}
                            keyExpr={'ID'}
                            dataSource={products}
                            parentIdExpr={'categoryId'}
                            autoExpandAll={true}
                            showRowLines={false}
                            showColumnHeaders={false}
                            id={'tasks'}
                            onContentReady={(e) => this.setDefaultRow(e)}
                            onRowClick={(e) => {
                                this.selectedRow(e)
                            }}
                            width={'100%'}
                        >
                            <HeaderFilter visible={true} />
                            <Selection mode={'single'} />
                            <Column dataField={'name'} caption={'Status'} width={300} />
                        </TreeList>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        {this.state.toggle === 'requiredField' && <div className="page-requiredField">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="grid-title title-field">
                                        <label>{Config.lang("CRM_Thiet_lap_truong_bat_buoc_nhap")}</label></div>
                                </Col>
                                <Col xs={12} sm={2} md={2} lg={2}>
                                    <label>{Config.lang("CRM_Phan_he")}</label>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <div>
                                        <Combo
                                            valueExpr={'CodeType'}
                                            dataSource={this.CbCodeType}
                                            placeholder={Config.lang("CRM_Phan_he")}
                                            value={codeType}
                                            displayExpr={'Name'}
                                            onActionWhenSelectChange={(data) => {
                                                this.onChangeComboCodeType(data)
                                            }}
                                        >
                                            <Column dataField={'CodeType'} caption="Mã" visible={false}
                                                        dataType={'string'}/>
                                                <Column dataField={'Name'} caption="Tên" dataType={'string'}/>
                                        </Combo>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <FieldRequired data10={this.dataSource} updateField={(m) => { this.updateChanged(m) }} totalItems={this.totalItems}
                                        itemPerField={this.filter.limit} onChangeField={(page) => { this.onChangePage(page) }}
                                        onChangePerField={(per) => { this.onChangePerPage(per) }}
                                        onCheckUpdateField={() => { this.onCheckUpdateField() }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <ButtonSave disabled={iPermission < 3} onClick={() => this.onEventSave()}
                                        className={"pull-right"}
                                        name={Config.lang("CRM_Luu")} />
                                </Col>
                            </Row>
                        </div>}
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        {this.state.toggle === 'customerSetting' && <CustomerSetting iPer={iPermission} />}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect(state => ({
    loadW17F0010: state.w17f0010.loadW17F0010,
    editW17F0010: state.w17f0010.editW17F0010,
    loadFieldRequiredW17F0010: state.w17f0010.loadFieldRequiredW17F0010,
}),
    (dispatch) => ({
        w17f0010Actions: bindActionCreators(w17f0010Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W17F0010Page);