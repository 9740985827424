/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/27/02 17:00
 * @update 2019/27/02 17:00
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w17f0003Actions from "../../actions/w17f0003-actions";
import Config from '../../config/index';
import GridContainer from "../common/grid-container/grid-container";
import {Column} from 'devextreme-react/data-grid';
import EventTracking from "../common/tracking/event";
import {browserHistory} from "react-router";
import {
    Row,
    Col,
    FormGroup, Button, Form,
} from 'react-bootstrap';
import Combo from "../common/dropdown/combo";
import {DateBox} from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import getAccountUserID from "../../actions/async/w17f0003/account-user-async";
import ButtonCustom from "../common/button/button-custom";

const createAccountCustomStore = {
    store: new CustomStore({
        key: "UserID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "search": loadOptions.searchValue,
            };
            if (loadOptions.filter) {
                // param.where = "UserID LIKE N'%" + loadOptions.filter.filterValue + "%'";
                param.where=JSON.stringify({
                    fieldName:'UserID',
                    operator:'LIKE',
                    value:loadOptions.filter.filterValue,
                })
            }
            const data = await getAccountUserID(param);
            const result = {
                'data': data.rows,
                'totalCount': data.total
            };
            return result;
        },
        byKey: async (key) => {
            const param = {
                // "where": "T1.UserID='" + key + "'"
                where:JSON.stringify({
                    fieldName:'T1.UserID',
                    operator:'LIKE',
                    value:key
                })
            };
            const data = await getAccountUserID(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};

class W17F0003Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        const {formID} = this.getInfo();
        let defautlastDay = '';
        let defautfirstDay = '';
        if (formID === 'W19F1010') {
            defautfirstDay = firstDay;
            defautlastDay = lastDay;
        }
        else {
            defautfirstDay = '';
            defautlastDay = '';
        }
        this.state = {
            showPopup: false,
            auditDateFrom: defautfirstDay,
            auditDateTo: defautlastDay,
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 10,
            search: ''
        };
    };

    componentDidMount() {
        if (!this.getInfo()) return;
        this.loadGrid();
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('LabelParam')) {
            return {labelFrom: url.get('LabelParam'), auditCode: url.get('AuditCode'), valueIDParam: url.get('ValueIDParam'), valueNameParam: url.get('ValueNameParam'), formID: url.get('FormID')};
        } else if (location && location.state) {
            return {
                labelFrom: location.state.LabelParam,
                auditCode: location.state.AuditCode,
                valueIDParam: location.state.ValueIDParam,
                valueNameParam: location.state.ValueNameParam,
                formID: location.state.FormID,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
                return null;
            }
            return false;
        }
    };

    formatDate(date) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [month, day, year].join('/');
        }
    }

    loadGrid() {
        const {formID,auditCode,valueIDParam} = this.getInfo();
        const historyParam = {
            "limit": this.filter.limit,
            "skip": this.filter.skip,
            "UserID": this.state.accountID,
            "AuditCode": auditCode,
            "ValueIDParam": valueIDParam,
            "FromID": formID,
            "AuditDateFrom": this.formatDate(this.state.auditDateFrom),
            "AuditDateTo": this.formatDate(this.state.auditDateTo),
            "StrSearch": this.filter.search,
        };
        this.props.w17f0003Actions.loadW17F0003(historyParam, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_haoc_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });
    }

    onChangeComboAccountID(data) {
        if (data) {
            this.setState({
                accountID: data
            });
        } else {
            this.setState({
                accountID: ''
            });
        }
    }

    onChangeAuditDateFrom(data) {
        this.setState({
            auditDateFrom: data.value
        })
    }

    onChangeAuditDateTo(data) {
        this.setState({
            auditDateTo: data.value
        })
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.search = text;
        this.loadGrid();
    }


    render() {
        if (!this.getInfo(true)) return null;
        const {formID,labelFrom,valueNameParam} = this.getInfo();
        const {loadW17F0003} = this.props;

        let totalItems = 0;
        let dataSource = [];
        if (loadW17F0003 && loadW17F0003.length > 0) {
            loadW17F0003.map(o => {
                const row = {
                    AuditID: o.AuditID,
                    AuditDate: o.AuditDate,
                    AuditCodeName: o.AuditCodeName,
                    UserName: o.UserName,
                    EventName: o.EventName,
                    Description: o.Description,
                    IP: o.IP,
                };
                totalItems = o.TotalRecord;
                dataSource.push(row);
                return o;
            });
        }

        return (
            <div className="page-container" style={{margin:0}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F0003"} label={Config.profile.UserID}/>}

                <div style={{marginLeft:'10px'}} className="grid-title">{Config.lang("CRM_Lich_su_tac_dong")} {labelFrom&&<span className={'mgr5'}>{labelFrom}</span>}{valueNameParam&&<span className={'mgr5'}>{valueNameParam}</span>}</div>
                <Form horizontal={true} id={'frmW17F0003'} style={{margin:'0 30px'}}>
                <FormGroup className={formID === 'W19F1010' ? 'mgb10' : 'hide'}>
                    <Row>
                        <Col md={1} sm={12} xl={2} xs={12} lg={1}>
                            <ButtonCustom color={'text-orange'} className={'mgr5'}
                                          icon={'fas fa-arrow-alt-left'}
                                          isAwesome={true} name={Config.lang("CRM_Quay_lai")}
                                          onClick={browserHistory.goBack}/>
                        </Col>
                        <Col md={11} sm={12} xl={2} xs={12} lg={11}>
                            <Row>
                                <Col md={4} sm={4} xl={2} xs={12} lg={3}>
                                    <Combo
                                        showClearButton={true}
                                        // required={true}
                                        virtual={true}
                                        showHeader={false}
                                        dataSource={createAccountCustomStore}
                                        placeholder={Config.lang("CRM_Tai_khoan")}
                                        value={this.state.accountID}
                                        valueExpr="UserID"
                                        displayExpr="UserID"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboAccountID(data)
                                        }}
                                    >
                                        <Column dataField={'UserID'} caption="Mã" dataType={'string'}/>
                                    </Combo>
                                </Col>

                                        <Col md={3} sm={3} xl={2} xs={12} lg={2}>
                                            <DateBox
                                                width={"100%"}
                                                placeholder={Config.lang("CRM_Tu_ngay")}
                                                id={'AuditDateFrom'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.auditDateFrom}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(data) => {
                                                    this.onChangeAuditDateFrom(data)
                                                }}
                                            />
                                        </Col>
                                        <Col md={3} sm={3} xl={2} xs={12} lg={2}>
                                            <DateBox
                                                width={"100%"}
                                                placeholder={Config.lang("CRM_Den_ngay")}
                                                id={'AuditDateTo'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.auditDateTo}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(data) => {
                                                    this.onChangeAuditDateTo(data)

                                                }}
                                            />
                                        </Col>
                                <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                    <Button type="button" className={'pull-left'} onClick={() => {
                                        this.loadGrid()
                                    }}> <i className="fas fa-search mgr5"/>{Config.lang("CRM_Tim_kiem")}</Button>
                                </Col>
                            </Row>


                        </Col>

                    </Row>

                </FormGroup>
                <FormGroup>
                    <GridContainer
                        dataSource={dataSource}
                        totalItems={totalItems}
                        itemPerPage={this.filter.limit}
                        onSearch={(text) => {
                            this.onSearch(text)
                        }}
                        onChangePage={(page) => {
                            this.onChangePage(page)
                        }}
                        onChangePerPage={(per) => {
                            this.onChangePerPage(per)
                        }}
                    >
                        <Column dataField={'UserName'} caption={Config.lang("CRM_Nguoi_dung")} minWidth={200}
                                width={400}/>
                        <Column alignment='center' format="dd/MM/yyyy" dataType={'date'} dataField={'AuditDate'}
                                caption={Config.lang("CRM_Ngay_gio")} allowSorting={true} allowReordering={false}
                                width={250}/>
                        <Column dataField={'EventName'} caption={Config.lang("CRM_Hanh_dong")} width={200}
                                dataType={'string'}/>
                        <Column dataField={'Description'} caption={Config.lang("CRM_Mo_ta")} width={450}
                                dataType={'string'}/>
                        <Column dataField={'AuditCodeName'} caption={Config.lang("CRM_Nghiep_vu")} width={250}
                                dataType={'string'}/>
                        <Column dataField={'IP'} caption={Config.lang("CRM_Dia_chi_IP")} width={300}
                                dataType={'string'}/>
                    </GridContainer>
                </FormGroup>
                </Form>
            </div>
        )
    }
}

export default connect(state => ({
        loadW17F0003: state.w17f0003.loadW17F0003,
        loadComboW17F0003: state.w17f0003.loadComboW17F0003,
    }),
    (dispatch) => ({
        w17f0003Actions: bindActionCreators(w17f0003Actions, dispatch)
    })
)(W17F0003Page);