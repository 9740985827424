/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/11/2022
 * @Example
 */
import { withStyles } from "@material-ui/styles";
import { Column } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as userActions from "../../actions/user-actions";
import * as w17f0060Actions from "../../actions/w17/w17F0060/w17f0060-actions";
import Config from "../../config";
import ButtonSave from "../common/button/button-save";
import Combo from "../common/dropdown/combo";
import EventTracking from "../common/tracking/event";
import PopupScroll from "../popup/popup-scroll";

const styles = () => {
    return {
        btnSave: {
            display: "block",
            margin: "15px 0 10px auto",
        },
        dropdownIconCb: {
            position: "absolute",
            top: 8,
            right: 26,
            pointerEvents: "none",
        },
    };
};

class W17F0060Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                cboMailServices: false,
                cboProtocols: false,
                cboPorts: false,
                loadForm: false,
                save: false,
            },
            dataCboMailServices: [],
            dataCboProtocols: [],
            dataCboPorts: [],
            formData: {
                AuthenTLS: "",
                PortMail: "",
                ServerIPAddress: "",
                SubjectEmail: "",
                TypeMail: "",
                UserEmail: "",
                UserPassword: "",
                SalesPersonID: ""
            },
            iPermission: 0,
        };
        this.loading = {
            getCboMailServices: false,
            getCboProtocols: false,
            getCboPorts: false,
            loadForm: false,
            save: false,
        };
        this.formData = {};
        this.timerInput = null;
        this.dataCboMailServices = [];
        this.dataCboProtocols = [];
        this.dataCboPorts = [];
    }

    setLoading = (obj = {}) => {
        this.loading = { ...this.loading, ...obj };
        this.setState({ loading: this.loading });
    };
    setData = (obj = {}, cb) => {
        this.formData = { ...this.formData, ...obj };
        this.setState({ formData: this.formData }, () => {
            if (cb) cb();
        });
    };

    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "W17F0060", per => {
            this.setState({ iPermission: per });
        });
    }

    componentDidMount = () => {
        this.setData({ SalesPersonID: this.props.data?.SalesPersonID || "" }, () => {
            this.loadForm();
        });
        this.loadCboMailServices();
        this.loadCboProtocols();
        this.loadCboPorts();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.formData?.ServerIPAddress !== this.state.formData?.ServerIPAddress) {
            this.setState(
                {
                    dataCboProtocols: this.dataCboProtocols.filter(
                        d => d.ServerIPAddress === this.state.formData?.ServerIPAddress
                    ),
                },
                () => {
                    if (prevState.formData?.ServerIPAddress) this.setData({ TypeMail: "" });
                }
            );
        }
        if (prevState.formData?.TypeMail !== this.state.formData?.TypeMail) {
            this.setState(
                { dataCboPorts: this.dataCboPorts.filter(d => d.TypeMail === this.state.formData?.TypeMail) },
                () => {
                    if (prevState.formData?.TypeMail) this.setData({ PortMail: "" });
                }
            );
        }
    }

    loadCboMailServices = () => {
        this.setLoading({ cboMailServices: true });
        this.props.w17f0060Actions.getCbo({ Mode: 0 }, (error, data) => {
            this.setLoading({ cboMailServices: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            }
            this.dataCboMailServices = data || [];
            this.setState({ dataCboMailServices: [...this.dataCboMailServices] });
        });
    };

    loadCboProtocols = () => {
        this.setLoading({ cboProtocols: true });
        this.props.w17f0060Actions.getCbo({ Mode: 1 }, (error, data) => {
            this.setLoading({ cboProtocols: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            }
            this.dataCboProtocols = data || [];
            this.setState({ dataCboProtocols: [...this.dataCboProtocols] });
        });
    };

    loadCboPorts = () => {
        this.setLoading({ cboProtocols: true });
        this.props.w17f0060Actions.getCbo({ Mode: 2 }, (error, data) => {
            this.setLoading({ cboProtocols: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            }
            this.dataCboPorts = data || [];
            this.setState({ dataCboPorts: [...this.dataCboPorts] });
        });
    };

    loadForm = () => {
        const { SalesPersonID } = this.state.formData || {};
        this.setLoading({ loadForm: true });
        this.props.w17f0060Actions.getCbo({ Mode: 3, SalesPersonID }, (error, data) => {
            this.setLoading({ loadForm: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                data = data[0] || data;
                this.setData(data);
                this.setInputValue(data);
            }
        });
    };

    onHide = () => {
        if (this.props.onHide) this.props.onHide();
    };

    onSave = () => {
        const { formData = {} } = this.state;
        const { SubjectEmail, ServerIPAddress, TypeMail, PortMail, UserEmail, UserPassword, AuthenTLS, SalesPersonID } =
            formData;
        if (_.isEmpty(SubjectEmail)) {
            const msg = `${Config.lang("Tieu_de_mail")} ${Config.lang("Khong_duoc_de_trong").toLowerCase()}`;
            Config.popup.show("INFO", msg);
            const el = document.querySelector('input[name="SubjectEmail"]');
            if (el) el.focus();
            return false;
        }
        const params = {
            SalesPersonID,
            SubjectEmail,
            ServerIPAddress,
            TypeMail,
            PortMail,
            UserEmail,
            UserPassword,
            AuthenTLS,
        };
        this.setLoading({ save: true });
        this.props.w17f0060Actions.save(params, (error, data) => {
            this.setLoading({ save: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            }
            if (data?.Status === 0) {
                this.onHide(); // Đóng Form và Reload Data
                notify(Config.lang("Luu_thanh_cong"), "success", 400);
            } else {
                const message = data?.Message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
            }
        });
    };

    setInputValue = data => {
        if (data) {
            Object.keys(data).forEach(name => {
                if (name === "UserPassword") return;
                const el = document.querySelector(`input[name="${name}"]`);
                if (el) el.value = data[name];
            });
        }
    };

    handleChange = (key, e) => {
        switch (key) {
            case "SubjectEmail":
            case "UserEmail":
            case "UserPassword":
            case "AuthenTLS":
                const { value } = e?.target || {};
                if (this.timerInput) clearTimeout(this.timerInput);
                this.timerInput = setTimeout(() => {
                    this.setData({ [key]: value });
                }, 500);
                break;
            default:
                break;
        }
    };

    handleCboChange = (key, e) => {
        this.setData({
            [key]: e[key],
            [key + "Name"]: e[key + "Name"],
        });
    };

    render() {
        const { classes } = this.props;
        const { loading, formData, dataCboMailServices, dataCboProtocols, dataCboPorts } = this.state;
        const disabled = !!Object.keys(loading).find(l => loading[l]);
        const isTLS = formData.TypeMail === "TLS";

        return (
            <PopupScroll
                title={Config.lang("Thiet_lap_thong_tin_Email")}
                show={true}
                className={"popup-size-70"}
                animation={true}
                onHide={this.onHide}
            >
                {Config && Config.profile && (
                    <EventTracking category={"TRACKING_USER"} action={"W17F0060"} label={Config.profile.UserID} />
                )}
                <div className={"page-container"} id={"frmW17F0060"}>
                    <Form horizontal={true}>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>
                                    {Config.lang("Tieu_de_mail")}{" "}
                                </label>
                            </Col>
                            <Col md={10} sm={10} xs={12} lg={10} style={{ marginBottom: 15 }}>
                                <input
                                    className={"form-control"}
                                    name={"SubjectEmail"}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={e => this.handleChange("SubjectEmail", e)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>
                                    {Config.lang("Email service")}{" "}
                                </label>
                            </Col>
                            <Col md={10} sm={10} xs={12} lg={10} style={{ marginBottom: 15 }}>
                                <Combo
                                    wordWrapEnabled={true}
                                    showHeader={false}
                                    virtual={false}
                                    disabled={disabled}
                                    styleCustom={{ paddingLeft: 0 }}
                                    dataSource={dataCboMailServices}
                                    value={formData.ServerIPAddress || ""}
                                    placeholder={Config.lang("Chon")}
                                    valueExpr={"ServerIPAddress"}
                                    displayExpr={"ServerIPAddressName"}
                                    onActionWhenSelectChange={obj => this.handleCboChange("ServerIPAddress", obj)}
                                >
                                    <Column
                                        dataField={"ServerIPAddressName"}
                                        width={"auto"}
                                        dataType={"string"}
                                        cellRender={e => `${e.row?.data?.ServerIPAddressName || ""}`}
                                    />
                                </Combo>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>{"SSL/TLS/None SSL"} </label>
                            </Col>
                            <Col
                                md={isTLS ? 6 : 10}
                                sm={isTLS ? 6 : 10}
                                xs={12}
                                lg={isTLS ? 6 : 10}
                                style={{ marginBottom: 15 }}
                            >
                                <Combo
                                    wordWrapEnabled={true}
                                    showHeader={false}
                                    virtual={false}
                                    disabled={disabled}
                                    styleCustom={{ paddingLeft: 0 }}
                                    dataSource={dataCboProtocols}
                                    value={formData.TypeMail || ""}
                                    placeholder={Config.lang("Chon")}
                                    valueExpr={"TypeMail"}
                                    displayExpr={"TypeMailName"}
                                    onActionWhenSelectChange={obj => this.handleCboChange("TypeMail", obj)}
                                >
                                    <Column
                                        dataField={"TypeMailName"}
                                        width={"auto"}
                                        dataType={"string"}
                                        cellRender={e => `${e.row?.data?.TypeMailName || ""}`}
                                    />
                                </Combo>
                            </Col>
                            {isTLS && (
                                <Col md={4} sm={4} xs={12} lg={4} style={{ marginBottom: 15 }}>
                                    <input
                                        className={"form-control"}
                                        name={"AuthenTLS"}
                                        placeholder={Config.lang("Nhap")}
                                        onChange={e => this.handleChange("AuthenTLS", e)}
                                        disabled={disabled}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>{"Port"} </label>
                            </Col>
                            <Col md={6} sm={6} xs={12} lg={6} style={{ marginBottom: 15 }}>
                                <Combo
                                    wordWrapEnabled={true}
                                    showHeader={false}
                                    virtual={false}
                                    disabled={disabled}
                                    styleCustom={{ paddingLeft: 0 }}
                                    dataSource={dataCboPorts}
                                    value={formData.PortMail || ""}
                                    placeholder={Config.lang("Chon")}
                                    valueExpr={"PortMail"}
                                    displayExpr={"PortMailName"}
                                    onActionWhenSelectChange={obj => this.handleCboChange("PortMail", obj)}
                                >
                                    <Column
                                        dataField={"PortMailName"}
                                        width={"auto"}
                                        dataType={"string"}
                                        cellRender={e => `${e.row?.data?.PortMailName || ""}`}
                                    />
                                </Combo>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>{Config.lang("Email")} </label>
                            </Col>
                            <Col md={10} sm={10} xs={12} lg={10} style={{ marginBottom: 15 }}>
                                <input
                                    className={"form-control"}
                                    name={"UserEmail"}
                                    type={"email"}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={e => this.handleChange("UserEmail", e)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12}>
                                <label className={`control-label ${classes.labelName}`}>
                                    {Config.lang("Password")}{" "}
                                </label>
                            </Col>
                            <Col md={10} sm={10} xs={12} lg={10} style={{ marginBottom: 15 }}>
                                <input
                                    className={"form-control"}
                                    name={"UserPassword"}
                                    type={"password"}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={e => this.handleChange("UserPassword", e)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <ButtonSave
                                    name={Config.lang("Luu")}
                                    disabled={disabled}
                                    className={`${classes.btnSave}`}
                                    onClick={() => this.onSave()}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </PopupScroll>
        );
    }
}

W17F0060Page.propTypes = {
    companyID: PropTypes.string,
    statusID: PropTypes.string,
    voucherID: PropTypes.string,
    formID: PropTypes.string,
    type: PropTypes.string,
    onHide: PropTypes.func,
};

export default compose(
    connect(null, dispatch => ({
        w17f0060Actions: bindActionCreators(w17f0060Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })),
    withStyles(styles, { withTheme: true })
)(W17F0060Page);
